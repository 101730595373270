<!-- components/LoginModal.vue -->
<!-- 弹窗使用方法
import { useStore } from 'vuex';  // 引入 useStore 钩子

// 使用 useStore 获取 Vuex Store 实例
const store = useStore();

// 定义 showLogin 方法，触发 Vuex 中的 action
const showLogin = () => {
  store.dispatch('authModal/showLoginModal');  // 触发命名空间 'authModal' 中的 'showLoginModal' action
}; 
-->
<template>
  <!-- 登录弹窗 -->
  <a-modal
    v-model:open="isLoginModalVisible"
    title="Login"
    @cancel="handleCancel"
  >
    <a-form
      :model="loginFormState"
      name="login_form"
      @finish="onLoginFinish"
      @finishFailed="onFinishFailed"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
    >
      <a-form-item
        :label="$t('login.username')"
        name="username"
        :rules="[{ required: true, message: $t('login.usernameRequired') }]"
      >
        <a-input v-model:value="loginFormState.username">
          <template #prefix>
            <UserOutlined class="site-form-item-icon" />
          </template>
        </a-input>
      </a-form-item>

      <a-form-item
        :label="$t('login.password')"
        name="password"
        :rules="[{ required: true, message: $t('login.passwordRequired') }]"
      >
        <a-input-password v-model:value="loginFormState.password">
          <template #prefix>
            <LockOutlined class="site-form-item-icon" />
          </template>
        </a-input-password>
      </a-form-item>

      <!-- Image Captcha -->
      <a-form-item
        :label="$t('login.captcha')"
        name="captcha"
        :rules="[{ required: true, message: $t('login.captchaRequired') }]"
      >
        <a-input v-model:value="loginFormState.captcha">
          <template #prefix>
            <PictureOutlined class="site-form-item-icon" />
          </template>
        </a-input>
        <img :src="captchaUrl" @click="refreshCaptcha" class="captcha-img" />
      </a-form-item>

      <a-form-item>
        <a-checkbox v-model:checked="loginFormState.remember">
          {{ $t('login.remember') }}
        </a-checkbox>
        <a class="login-form-forgot" href="" @click="onRecover">{{ $t('login.forgot') }}</a>
      </a-form-item>

      <a-form-item>
        <a-button :disabled="loginDisabled" type="primary" html-type="submit" class="login-form-button">
          {{ $t('login.login') }}
        </a-button>
        {{ $t('login.or') }}
        <a @click="switchToRegister">{{ $t('login.register') }}</a>
      </a-form-item>
    </a-form>
  </a-modal>

</template>

<script setup>
import { computed, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { UserOutlined, LockOutlined, PictureOutlined, MailOutlined } from '@ant-design/icons-vue';
import axios from '@/axios';
import axios_blob from '@/axios_blob';

const { t } = useI18n();
const store = useStore();

// 登录表单数据
const loginFormState = reactive({
  username: '',
  password: '',
  captcha: '',
  remember: true,
});


// 是否显示登录/注册弹窗
const isLoginModalVisible = computed(() => store.state.authModal.isLoginModalVisible);

// 验证码 URL
const captchaUrl = ref('');
const refreshCaptcha = async () => {
  try {
    const response = await axios_blob.get(`userauth/get_image_captcha/?source=login&time=${Date.now()}`);
    captchaUrl.value = URL.createObjectURL(response.data);
  } catch (error) {
    console.error(t('login.captchaRefreshFailed'), error);
  }
};

// 页面加载时获取验证码
refreshCaptcha();

// 登录表单提交处理
const onLoginFinish = async () => {
  try {
    const response = await axios.post('userauth/login/', loginFormState);
    if (response.data.status !== 10000) {
      refreshCaptcha();
      return;
    }
    const accessToken = response.data.data.token;
    localStorage.setItem('access_token', accessToken);

    // 获取用户信息
    const userInfoResponse = await axios.post('web/user_info/');
    const userInfo = userInfoResponse.data.data;

    // 更新 Vuex 状态
    store.commit('SET_AUTHENTICATED', true);
    store.commit('SET_USER', userInfo);

    // 关闭弹窗
    store.dispatch('authModal/hideLoginModal');
  } catch (error) {
    console.error(error);
    refreshCaptcha();
  }
};


// 表单提交失败处理
const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};


// 关闭弹框
const handleCancel = () => {
  store.dispatch('authModal/hideLoginModal');
};

// 是否禁用提交按钮
const loginDisabled = computed(() => {
  return !(loginFormState.username && loginFormState.password && loginFormState.captcha);
});



// 忘记密码点击
const onRecover = () => {
  // 处理忘记密码
};
</script>

<style scoped>
.captcha-img {
  cursor: pointer;
  margin-top: 10px;
}
</style>
