<template>
  <div :class="$style.wrapOut">
    <!-- <h1>{{ newsContent.title }}</h1> -->
    <div class="content" v-if="newsContent">
        <div v-html="newsContent.introduction|| ''"></div>
    </div>


  </div>

</template>
  
<script setup>
  import { ref, onMounted, watch } from "vue";
  import axios from "@/axios";
  import { message } from "ant-design-vue";
  import { useRoute, useRouter } from 'vue-router'

  const route = useRoute();
  const router = useRouter();
  
  
  const newsContent = ref("");
  
  // 请求新闻详情
  const fetchNewsDetail = async (pk) => {
    try {
      const res = await axios.post("/web/news/", { pk });
      if (res?.data?.status === 10000) {
        newsContent.value = res?.data?.data;
      } else {
        message.error("获取新闻详情失败");
      }
    } catch (error) {
      message.error("请求新闻详情时发生错误");
    }
  };
  
  // 初次加载时请求新闻详情
  onMounted(() => {
    if (!route.params?.id) return;
      fetchNewsDetail(route.params?.id);
    });
</script>
  
<style module>
.wrapOut {
  padding: 43px 230px 0;
  transform: scale(0.82);
  transform-origin: 50% 0;
}
</style>
  