<template>
  <a-modal
    :open="isOpen"
    title="提现验证"
    @ok="handleOk"
    @cancel="handleCancel"
    cancel-text="取消"
    ok-text="确认"
    :maskClosable="false"
    :confirmLoading="isLoading"
    :destroyOnClose="true"
  >
    <a-form
      :model="formState"
      name="withdrawForm"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 16 }"
      autocomplete="off"
      style="margin-top: 24px"
    >
      <a-form-item label="图片验证码" name="captcha" v-bind="validateInfos.captcha">
        <a-input v-model:value="formState.captcha">
          <template #prefix>
            <PictureOutlined class="site-form-item-icon" />
          </template>
        </a-input>
        <img
          :src="captchaUrl"
          @click="refreshCaptcha"
          :class="$style.captchaImg"
        />
      </a-form-item>

      <!-- 根据认证方式显示短信或邮箱验证码 -->
      <a-form-item
        v-if="authMethod === 1"
        label="短信验证码"
        name="sms_code"
        v-bind="validateInfos.sms_code"
      >
        <a-input v-model:value="formState.sms_code" />
        <a-button @click="getSmsCode" :disabled="smsButtonDisabled" style="margin-top: 10px">
          {{ smsButtonText }}
        </a-button>
      </a-form-item>
      
      <a-form-item
        v-else-if="authMethod === 2"
        label="邮箱验证码"
        name="email_code"
        v-bind="validateInfos.email_code"
      >
        <a-input v-model:value="formState.email_code" />
        <a-button @click="getEmailCode" :disabled="smsButtonDisabled" style="margin-top: 10px">
          {{ smsButtonText }}
        </a-button>
      </a-form-item>

    </a-form>
  </a-modal>
</template>

<script setup>
import { onMounted, reactive, ref, defineProps } from "vue";
import { PictureOutlined } from '@ant-design/icons-vue';
import { Form, message } from "ant-design-vue";
import axios from "@/axios";
import axios_blob from '@/axios_blob';

const { isOpen, user } = defineProps({
  isOpen: Boolean,
  user: Object,
});
const emit = defineEmits(["setIsOpen"]);
const useForm = Form.useForm;
const isLoading = ref(false);
const captchaUrl = ref("");
const smsButtonDisabled = ref(false);
const smsButtonText = ref('获取验证码');

// 根据 auth_method 判断是短信验证还是邮箱验证
const authMethod = user.auth_method ?? 0; // 1 = 短信, 2 = 邮箱

// 刷新图形验证码
const refreshCaptcha = async () => {
  try {
    const response = await axios_blob.get(
      `userauth/get_image_captcha/?source=withdraw&time=${Date.now()}`
    );
    captchaUrl.value = URL.createObjectURL(response.data);
  } catch (error) {
    console.error("获取验证码失败:", error);
  }
};

// 页面加载时获取验证码
onMounted(refreshCaptcha);

// 获取短信验证码
const getSmsCode = async () => {
  if (!formState.captcha || !user.mobile) {
    message.error('请先输入图片验证码或手机号');
    return;
  }
  try {
    const response = await axios.post('userauth/get_code_by_authmethod/', {
      captcha: formState.captcha,
      mobile: user.mobile
    });
    if (response.data.status === 10000) {
      startCountdown();
    } else {
      refreshCaptcha();
    }
  } catch (error) {
    console.error(error);
    refreshCaptcha();
  }
};

// 获取邮箱验证码
const getEmailCode = async () => {
  if (!formState.captcha || !user.email) {
    message.error('请先输入图片验证码或邮箱');
    return;
  }
  try {
    const response = await axios.post('userauth/get_code_by_authmethod/', {
      captcha: formState.captcha,
      email: user.email
    });
    if (response.data.status === 10000) {
      startCountdown();
    } else {
      refreshCaptcha();
    }
  } catch (error) {
    console.error(error);
    refreshCaptcha();
  }
};

// 开始倒计时
const startCountdown = () => {
  smsButtonDisabled.value = true;
  smsButtonText.value = '120秒后重新获取';
  let countdown = 120;
  const interval = setInterval(() => {
    countdown -= 1;
    smsButtonText.value = `${countdown}秒后重新获取`;
    if (countdown <= 0) {
      clearInterval(interval);
      smsButtonDisabled.value = false;
      smsButtonText.value = '获取验证码';
    }
  }, 1000);
};

// 表单状态与验证规则
const formState = reactive({
  captcha: "",
  sms_code: "",
  email_code: "",
});
const rulesRef = reactive({
  captcha: [
    {
      required: true,
      message: "请输入图片验证码",
    },
  ],
  sms_code: [
    {
      required: authMethod === 1,
      message: "请输入短信验证码",
    },
  ],
  email_code: [
    {
      required: authMethod === 2,
      message: "请输入邮箱验证码",
    },
  ],
});

const { validate, validateInfos, resetFields } = useForm(formState, rulesRef);

// 点击确认时验证表单并提交
const handleOk = async () => {
  const values = await validate();
  isLoading.value = true;
  const res = await axios.post("web/withdraw/", values).catch(() => {});
  isLoading.value = false;
  if (res?.data?.status === 10000) {
    resetFields();
    emit("setIsOpen", false);
    return;
  }
  refreshCaptcha();
};

// 点击取消时重置表单并关闭弹窗
const handleCancel = async () => {
  resetFields();
  emit("setIsOpen", false);
};
</script>

<style module>
  .captchaImg {
    cursor: pointer;
    margin-top: 10px;
  }
</style>
