<template>
  <div>
    <a-modal
      v-model:open="isOpen"
      title="实名认证"
      @ok="handleOk"
      @cancel="handleCancel"
      cancel-text="取消"
      ok-text="确认"
      :maskClosable="false"
      :confirmLoading="isLoading"
      :destroyOnClose="true"
    >
      <a-form
        :model="formState"
        name="basic"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
        autocomplete="off"
      >
        <a-form-item
          label="姓名"
          name="realname"
          v-bind="validateInfos.realname"
        >
          <a-input v-model:value="formState.realname" />
        </a-form-item>
        <a-form-item
          label="身份证号"
          name="id_num"
          v-bind="validateInfos.id_num"
        >
          <a-input
            v-model:value="formState.id_num"
            @blur="validate('id_num', { trigger: 'blur' }).catch(() => {})"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script setup>
import { computed, reactive, ref } from "vue";
import { useStore } from "vuex";
import { Form, message } from "ant-design-vue";
import { checkIdCard } from "@/util/utils";
import axios from "@/axios";

const store = useStore();
const useForm = Form.useForm;
const isOpen = computed(() => store?.state?.realnameVerificationModal.isRealOpen || false);
const isLoading = ref(false);

const validateIdNum = async (_rule, value) => {
  if (!checkIdCard(value)) {
    return Promise.reject("请输入正确的身份证号");
  } else {
    return Promise.resolve();
  }
};

const formState = reactive({
  realname: "",
  id_num: "",
});
const rulesRef = reactive({
  realname: [
    {
      required: true,
      message: "请输入姓名",
    },
  ],
  id_num: [
    {
      required: true,
      message: "请输入身份证号",
    },
    {
      validator: validateIdNum,
      trigger: "blur",
    },
  ],
});

const { validate, validateInfos, resetFields } = useForm(formState, rulesRef);

const handleOk = async () => {
  const values = await validate();
  isLoading.value = true;
  const res = await axios.post("web/verify_idcard/", values).catch(() => {});
  isLoading.value = false;
  if (res?.data?.status === 10000) {
    resetFields();
    store.dispatch("realnameVerificationModal/openRealModal");
  }
};

const handleCancel = async () => {
  resetFields();
  store.dispatch("realnameVerificationModal/closeRealModal");
};
</script>