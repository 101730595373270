<template>
  <div :class="$style.wrap">
    <div :class="$style.bannerWrap">
      <a-carousel
        autoplay
        :autoplaySpeed="5000"
        :dotsClass="$style.bannerDots"
        class="headerBanner"
        :after-change="onAfterChange"
        ref="carouselRef"
      >
        <img
          v-for="item in banners"
          :class="$style.bannerIem"
          :src="getFullImageUrl(item?.image)"
          alt=""
          @click="onClickBanner(item)"
        />
      </a-carousel>
      <div :class="$style.bannerTitle">
        <div
          v-for="(item, idx) in banners"
          :class="[$style.bannerTitleItem, idx === currentBanner && $style.activeBanner]"
          @click="onClickTitle(idx)"
        >
          {{item?.name || '-'}}
        </div>
      </div>
      <div :class="$style.bannerFloat"></div>
    </div>
    <HotGame />
    <OffersGame />
    <div :class="$style.recommendWrap">
      <Recommend />
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted } from "vue";
  import { useRouter } from 'vue-router'
  import ECarousel from '@components/ECarousel.vue';
  import ProductCard from '@components/ProductCard.vue';
  import HotGame from './HotGame.vue';
  import OffersGame from './OffersGame.vue';
  import Recommend from './Recommend.vue';
  import axios from "@/axios";
  import { getFullImageUrl } from "@/util/utils.js";

  const router = useRouter();
  const carouselRef = ref();
  const banners = ref([]);
  const currentBanner = ref(0);

  onMounted(async () => {
    const res = await axios.post("web/banners/").catch(() => {});
    if (res?.data?.status === 10000) {
      banners.value = res?.data?.data?.items || [];
    }
  });

  // 点击头部banner
  const onClickBanner = (item) => {
    // router.push(`/detail/${item?.id || undefined}`);
    window.open(item?.link);
  }

  // 头部banner切换后回调
  const onAfterChange = (current) => {
    currentBanner.value = current;
  }

  // 点击头部banner右侧title
  const onClickTitle = (idx) => {
    carouselRef.value.goTo(idx);
  }
</script>

<style module>
  .wrap {
    height: 100%;
  }
  .bannerIem {
    width: 100%;
    cursor: pointer;
  }
  .bannerDots {
    margin-bottom: 320px;
  }
  .bannerWrap {
    position: relative;
  }
  .bannerTitle {
    position: absolute;
    top: 26px;
    right: 230px;
    min-width: 430px;
    border-radius: 10px;
    background-color: rgba(255,255,255,.24);
    font-size: 28px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 34px 34px;
    z-index: 99;
  }
  .bannerTitleItem {
    cursor: pointer;
  }
  .bannerTitleItem:not(:last-child) {
    margin-bottom: 23px;
  }
  .activeBanner {
    font-size: 36px;
    color: #FFE202;
  }
  .bannerFloat {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 437px;
    background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1))
  }
  .recommendWrap {
    padding: 0 230px 173px 230px;
  }
</style>
<style>
  .headerBanner .slick-dots>li>button {
    width: 15px !important;
    height: 15px !important;
    border-radius: 15px !important;
  }
  .headerBanner .slick-dots .slick-active>button {
    width: 25px !important;
    height: 15px !important;
    background-color: #3149FF !important;
    border-radius: 15px !important;
    border: 2px solid #fff !important;
  } 
</style>
