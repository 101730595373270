<template>
  <div>
    <!-- 新游推荐 -->
    <div :class="$style.new">
      <GamesReBanner
        :left-icon="newIcon"
        :left-img="leftImg"
        :right-img="rightImg"
        left-title="新游推荐"
        :game-infos="getGameInfos(newGames)"
        @on-click-more="onClickMoreNew"
      />
    </div>
    <!-- 3A大作 -->
    <div :class="$style.sA">
      <GamesReBanner
        :left-icon="sAIcon"
        :left-img="bLImg"
        :right-img="bLRmg"
        left-title="3A大作"
        :game-infos="getGameInfos(sAGames)"
        @on-click-more="onClickMoreSA"
      />
    </div>
    <!-- 冒险恐怖 -->
    <div :class="$style.risk">
      <GamesReBanner
        :left-icon="riskIcon"
        :left-img="rLImg"
        :right-img="rRImg"
        left-title="冒险恐怖"
        :game-infos="getGameInfos(riskGames)"
        @on-click-more="onClickMoreRisk"
      />
    </div>
    <!-- 双人合作 -->
    <div :class="$style.double">
      <GamesReBanner
        :left-icon="doubleIcon"
        :left-img="gLImg"
        :right-img="gRImg"
        left-title="双人合作"
        :game-infos="getGameInfos(doubleGames)"
        @on-click-more="onClickMoreDouble"
      />
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted } from "vue";
  import { useRouter } from 'vue-router'
  import GamesReBanner from './components/GamesReBanner.vue';
  import axios from "@/axios";
  import newIcon from "@assets/newIcon.png";
  import sAIcon from "@assets/sAIcon.png";
  import riskIcon from "@assets/riskIcon.png";
  import doubleIcon from "@assets/doubleIcon.png";
  import leftImg from "@assets/leftImg.png";
  import rightImg from "@assets/rightImg.png";
  import bLImg from "@assets/bLImg.png";
  import bLRmg from "@assets/bRImg.png";
  import rLImg from "@assets/rLImg.png";
  import rRImg from "@assets/rRImg.png";
  import gLImg from "@assets/gLImg.png";
  import gRImg from "@assets/gRImg.png";

  const router = useRouter();
  const newGames = ref([]);
  const sAGames = ref([]);
  const riskGames = ref([]);
  const doubleGames = ref([]);

  // 获取新游推荐
  onMounted(async () => {
    const res = await axios.post("web/games-recommend/", { method: 'new-rec' }).catch(() => {});
    if (res?.data?.status === 10000) {
      newGames.value = res?.data?.data || [];
    }
  });

  // 获取3A大作
  onMounted(async () => {
    const res = await axios.post("web/games-recommend/", { method: '3a-rec' }).catch(() => {});
    if (res?.data?.status === 10000) {
      sAGames.value = res?.data?.data || [];
    }
  });

  // 获取冒险恐怖
  onMounted(async () => {
    const res = await axios.post("web/games-recommend/", { method: 'horror-rec' }).catch(() => {});
    if (res?.data?.status === 10000) {
      riskGames.value = res?.data?.data || [];
    }
  });

  // 获取双人合作
  onMounted(async () => {
    const res = await axios.post("web/games-recommend/", { method: 'two-rec' }).catch(() => {});
    if (res?.data?.status === 10000) {
      doubleGames.value = res?.data?.data || [];
    }
  });

  const getGameInfos = (infos) => {
    const arr = [];
    const step = Math.ceil(infos?.length / 4);
    for(let i = 0; i < step; i++) {
      arr.push(infos?.slice(i * 4, (i + 1) * 4))
    }
    return arr;
  }

  // 点击新游推荐更多
  const onClickMoreNew = () => {
    router.push(`/gameStore`);
  }

  // 点击3A大作更多
  const onClickMoreSA = () => {
    router.push(`/gameStore?category=16`);
  }

  // 点击冒险恐怖更多
  const onClickMoreRisk = () => {
    router.push(`/gameStore?category=17`);
  }

  // 点击双人合作更多
  const onClickMoreDouble = () => {
    router.push(`/gameStore?category=10`);
  }

</script>

<style module>
  .new {
    margin-bottom: 48px;
  }
  .sA {
    margin-bottom: 48px;
  }
  .risk {
    margin-bottom: 48px;
  }
  .double {
    margin-bottom: 48px;
  }
</style>
