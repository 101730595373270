<template>
  <div :class="$style.wrap">
    <!-- 左侧订单类型筛选 -->
    <div :class="$style.left">
      <div :class="[$style.leftI, orderType === 'all' && $style.leftIActive]" @click="onChangeOT('all')">所有订单</div>
      <div :class="[$style.leftI, orderType === 'paid' && $style.leftIActive]" @click="onChangeOT('paid')">已支付订单</div>
      <div :class="[$style.leftI, orderType === 'unPaid' && $style.leftIActive]" @click="onChangeOT('unPaid')">待支付订单</div>
    </div>
    <!-- 右侧订单表格 -->
    <div :class="$style.right">
      <div :class="$style.searchI">
        <a-input-search
          v-model:value="curGameName"
          placeholder="请输入游戏名称"
          style="width: 100%; height: 100%"
          @search="onSearchGameName"
        />
      </div>
      <div :class="$style.rightTableW">
        <a-table
          :columns="orderColumns"
          :data-source="dataSource?.items"
          :loading="loading"
          row-key="order_number"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <!-- 动态渲染操作列 -->
          <template v-slot:bodyCell="{ column, record }">
            <div v-if="column?.key === 'status'" v-html="renderStatusBadge(record.status)"></div>
            <div v-else-if="column?.key === 'operation'">
              <a-button
                v-if="getCurrentAction(record)"
                :type="getCurrentAction(record).type"
                :disabled="getCurrentAction(record).disabled"
                @click="getCurrentAction(record).action"
              >
                {{ getCurrentAction(record).text }}
              </a-button>
            </div>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>


<script setup>
  import { ref, computed } from "vue";
  import { usePagination } from "vue-request"; // 假设使用 ahooks 的 usePagination
  import axios from "@/axios";
  import { useRouter } from "vue-router";
  const router = useRouter();

  // 定义 navigateTo 方法
  const navigateTo = (path) => {
    router.push(path);
  };

  // 筛选订单类型
  const orderType = ref("all");
  const curGameName = ref(undefined);

  // 表格列定义
  const orderColumns = [
    { title: "订单号", dataIndex: "order_number", key: "order_number" },
    { title: "游戏名", dataIndex: "gname", key: "gname", width: '10%' },
    { title: "商品名称", dataIndex: "sku_name", key: "sku_name", width: '12%' },
    { title: "账号类型", dataIndex: "sku_value", key: "sku_value", width: '12%' },
    { title: "金额", dataIndex: "real_price", key: "real_price", width: '10%', },
    {
      title: "订单状态",
      dataIndex: "status",
      key: "status",
      width: '12%',
    },
    { title: "创建时间", dataIndex: "order_created", key: "order_created" },
    { title: "操作", key: "operation" },
  ];

  // 渲染状态徽章
  const renderStatusBadge = (status) => {
    const statusMap = {
      0: { text: "待支付" },
      1: { text: "支付成功" },
      3: { text: "退款中" },
      4: { text: "超时取消" },
      5: { text: "退款成功" },
      6: { text: "退款失败" },
      default: { text: "支付失败" },
    };

    const { text } = statusMap[status] || statusMap.default;
    return `<span>${text}</span>`;
  };

  // 获取订单数据
  const fetchOrderData = async ({ page, limit, gameName }) => {
    const offset = (page - 1) * limit;
    const response = await axios.post("web/orders/", {
      game_name: gameName,
      status: orderType.value,
      limit: limit,
      page: page,
    });
    return {
      total: response.data.data.total,
      items: response.data.data.items,
    };
  };

  // 管理分页
  const {
    data: dataSource,
    run,
    loading,
    current,
    pageSize,
    total,
  } = usePagination(fetchOrderData, {
    formatResult: (res) => ({
      total: res.total,
      items: res.items,
    }),
    pagination: {
      currentKey: "page",
      pageSizeKey: "limit",
      defaultPageSize: 10,
    },
  });

  // 分页配置
  const pagination = computed(() => ({
    total: total.value,
    current: current.value,
    pageSize: pageSize.value || 10,
    pageSizeOptions: ["5", "10", "20", "50"],
    showSizeChanger: true,
    showTotal: (total) => `共 ${total} 条`,
  }));

  // 搜索游戏名称
  const onSearchGameName = (gameName) => {
    if (!gameName) {
      message.error("请输入游戏名称");
      return;
    }
    run({ page: 1, limit: pageSize.value, gameName });
  };

  // 切换订单类型
  const onChangeOT = (type) => {
    orderType.value = type;
    run({ page: 1, limit: pageSize.value });
  };

  // 动态获取当前操作按钮
  const getCurrentAction = (record) => {
    const { status, type, sku_value, is_have_account } = record;

    if (status === 0) {
      // 待支付
      return {
        text: "去支付",
        type: "primary",
        disabled: false,
        action: () => payOrder(record),
      };
    } else if (status === 1) {
      if (type === 1 || ["月会员", "季会员", "年会员", "永久会员"].includes(sku_value)) {
        return {
          text: "查看会员记录",
          type: "primary",
          disabled: false,
          action: () => navigateTo("/myMember"),
        };
      } else if (type === 2) {
        return {
          text: "购买CDK",
          type: "primary",
          disabled: false,
          action: () => navigateTo("/buyCdk"),
        };
      } else if (sku_value === "永久") {
        if (is_have_account) {
          return {
            text: "前往永久权益租号",
            type: "primary",
            disabled: false,
            action: () => navigateTo("/myGame"),
          };
        } else {
          return {
            text: "联系补卡客服",
            type: "warning",
            disabled: false,
            action: () => contactSupport(),
          };
        }
      } else if (sku_value === "独享") {
        if (is_have_account) {
          return {
            text: "跳转-独享账号",
            type: "primary",
            disabled: false,
            action: () => navigateTo("/exclusive"),
          };
        } else {
          return {
            text: "手动获取账号",
            type: "primary",
            disabled: false,
            action: () => buhao(record),
          };
        }
      } else if (["3小时", "6小时", "12小时"].includes(sku_value)) {
        if (is_have_account) {
          return {
            text: "跳转-租用记录",
            type: "primary",
            disabled: false,
            action: () => navigateTo("/rendResult"),
          };
        } else {
          return {
            text: "手动获取账号",
            type: "primary",
            disabled: false,
            action: () => buhao(record),
          };
        }
      }
    }

    return {
      text: "操作不可用",
      type: "default",
      disabled: true,
      action: null,
    };
  };

  // 支付订单
  const payOrder = (record) => {
    console.log("支付订单:", record);
    // 获取支付二维码，填充到弹窗中
  };

  const buhao = (record) => {
    axios.post("web/orders/buhao/", {uid: record.id})
  };
  // 联系客服
  const contactSupport = () => {
    window.open("https://work.weixin.qq.com/kfid/kfc15c98365afb7f089", "_blank");
  };

  // 表格分页、筛选变化
  const handleTableChange = (pag, filters) => {
    run({
      limit: pag?.pageSize,
      page: pag?.current,
      ...filters,
    });
  };
</script>

<style module>
  .wrap {
    display: flex;
    padding: 23px 230px 0;
  }
  .left {
    flex-shrink: 0;
    width: 264px;
    background-color: #FAFBFA;
    border-radius: 10px;
    padding: 22px 22px;
    color: #1A1A1A;
    margin-right: 46px;
  }
  .leftCon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20px;
    line-height: 20px;
  }
  .leftConL {
    font-size: 14px;
  }
  .leftF {
    height: 33px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .leftFP {
    display: flex;
    align-items: center;
  }
  .leftFPP {
    font-size: 22px;
    color: #FF2727;
    margin-right: 5px;
  }
  .leftFPD {
    font-size: 16px;
    color: #383838;
  }
  .leftFB {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 62px;
    height: 24px;
    font-size: 13px;
    color: #fff;
    background-color: rgba(255, 147, 94, 1);
  }
  .leftFB:hover {
    background-color: rgba(255, 147, 94, .7) !important;
  }
  .leftI {
    width: 100%;
    height: 41px;
    line-height: 41px;
    border-radius: 5px;
    font-size: 18px;
    color: #1A1A1A;
    padding-left: 19px;
    margin-bottom: 8px;
    cursor: pointer;
  }
  .leftIActive {
    background-color: #BDD6FD;
  }
  .right {
    flex-grow: 1;
  }
  .searchI {
    width: 426px;
    height: 50px;
    margin-left: auto;
  }
  .rightTableW {
    width: 100%;
  }
</style>
