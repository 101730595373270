<template>
  <div :class="$style.wrap">
    <!-- 平台筛选 -->
    <div :class="$style.gameList">
      <div
        :class="[$style.gameListI, searchValue.platform === null && $style.gameListIActive]"
        @click="onChangePlatform(null)"
      >
        所有游戏
      </div>
      <div
        v-for="platform in platforms"
        :key="platform.id"
        :class="[$style.gameListI, searchValue.platform === platform.id && $style.gameListIActive]"
        @click="onChangePlatform(platform.id)"
      >
        <img :class="$style.gameListII" :src="getPlatformIcon(platform.name)" alt="" />
        <div>{{ platform.name }}</div>
      </div>
      <div :class="[$style.gameListI, searchValue?.gamePingtai === 'phone' && $style.gameListIActive]" @click="onChangeGType('phone')">
        <img :class="$style.gameListIP" :src="gamePIcon" alt="" />
        <div>手机游戏</div>
      </div>
    </div>

    <!-- 排序筛选 -->
    <div :class="$style.sortType">
      <div
        :class="[$style.sortTypeI, searchValue.sort === 'default' && $style.sortTypeIActive]"
        @click="onChangeSort('default')"
      >
        <div>默认</div>
        <img :class="$style.sortTypeII" :src="downSIcon" alt="" />
      </div>
      <div
        :class="[$style.sortTypeI, searchValue.sort === 'new' && $style.sortTypeIActive]"
        @click="onChangeSort('new')"
      >
        <div>最新</div>
        <img :class="$style.sortTypeII" :src="downSIcon" alt="" />
      </div>
      <div
        :class="[$style.sortTypeI, searchValue.sort === 'sale' && $style.sortTypeIActive]"
        @click="onChangeSort('sale')"
      >
        <div>销量</div>
        <img :class="$style.sortTypeII" :src="downSIcon" alt="" />
      </div>
    </div>

    <!-- 推荐游戏 -->
    <div :class="$style.topPic">
      <div v-for="item in recommendedGames.slice(0, 5)" :key="item.id" :class="$style.topPicI" @click="onClickGame(item)">
        <img :class="$style.topPicII" :src="getFullImageUrl(item.img)" alt="" />
        <div :class="$style.topPicGN">{{ item.gname }}</div>
        <div :class="$style.topPicGE">{{ item.ename }}</div>
        <div :class="$style.divDir"></div>
      </div>
    </div>

    <div :class="$style.cutBgc"></div>
    <div :class="$style.contentTit">游戏搜索方法：《我被美女包围了》，搜索“美女”、“包围”，都可以找到该游戏,如果没有找到您想要的游戏，请联系客服。</div>
    <div :class="$style.gameRes">
      <div :class="$style.gameResLeft">
        <!-- 游戏列表 -->
        <div v-for="item in games" :key="item.id" :class="$style.gameResItem">
          <div :class="$style.gameResItemIn">
            <img :class="$style.gameResItemInI" :src="getFullImageUrl(item.img)" alt="" @click="onClickGame(item)" />
            <div :class="$style.gameResItemInfo">
              <a-tooltip>
                <template #title>{{ item.gname || '-' }}</template>
                <div :class="$style.gameResItemInfoG">{{ item.gname || '-' }}</div>
              </a-tooltip>
              <a-tooltip>
                <template #title>{{ item.ename || '-' }}</template>
                <div :class="$style.gameResItemInfoE">{{ item.ename || '-' }}</div>
              </a-tooltip>
              <div :class="$style.gameResItemInfoT">
                <div :class="$style.gameResItemInfoTI">{{ item.categories[0] || '未知类型' }}</div>
              </div>
            </div>
            <div :class="$style.gameResItemInP">{{ item.platform }}</div>
            <div :class="$style.gameResItemInPw">
              <div :class="$style.gameResItemInPrice">
                <div :class="$style.gameResItemInPriceI">券后</div>
                <div :class="$style.gameResItemInPriceP">¥{{ item?.sku_price_info?.show_price_info?.price }}</div>
              </div>
              <div :class="$style.gameResItemInPriceB">
                <div :class="$style.gameResItemInPriceBL">¥{{ item?.sku_price_info?.show_price_info?.game_price }}</div>
                <div :class="$style.gameResItemInPriceBR">{{ item?.sku_price_info?.show_price_info?.game_discount_rate || 0 }}%OFF</div>
              </div>
            </div>
          </div>
          <div :class="$style.gameResItemDir"></div>
        </div>

        <!-- 分页 -->
        <a-pagination
          :current="searchValue.page"
          :pageSize="searchValue.limit"
          :total="total"
          @change="onPageChange"
          @showSizeChange="onPageSizeChange"
        />
      </div>

      <!-- 筛选条件 -->
      <div :class="['gameResRight', $style.gameResRight]">
        <a-collapse v-model:activeKey="activeKey" expand-icon-position="end" :bordered="false">
          <a-collapse-panel key="1" header="游戏平台">
            <a-radio-group v-model:value="searchValue.platform" style="width: 100%">
              
              <a-row>
                <a-col :span="12">
                  <a-radio value="all">全部</a-radio>
                </a-col>
                <a-col :span="12" v-for="platform in platforms" :key="platform.id">
                  <a-radio :value="platform.id">{{ platform.name }}</a-radio>
                </a-col>
              </a-row>
            </a-radio-group>
          </a-collapse-panel>
          <a-collapse-panel key="2" header="游戏级别">
            <a-radio-group v-model:value="searchValue.level" style="width: 100%">
              <a-row>
                <a-col :span="12">
                  <a-radio value="all">全部</a-radio>
                </a-col>
                <a-col :span="12" v-for="level in levels" :key="level.id">
                  <a-radio :value="level.id">{{ level.name }}</a-radio>
                </a-col>
              </a-row>
            </a-radio-group>
          </a-collapse-panel>
          <a-collapse-panel key="3" header="游戏类型">
            <a-checkbox-group v-model:value="searchValue.category" style="width: 100%">
              <a-row>
                <a-col :span="12" v-for="category in categories" :key="category.id">
                  <a-checkbox :value="category.id">{{ category.name }}</a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, reactive, onMounted, watch, computed } from "vue";
  import { useRouter } from "vue-router";
  import { useStore } from "vuex";
  import axios from "@/axios";
  import { getFullImageUrl } from "@/util/utils.js";

  // 图标
  import steamIcon from "@assets/steamIcon.png";
  import epicIcon from "@assets/epicIcon.png";
  import ybIcon from "@assets/ybIcon.png";
  import downSIcon from "@assets/downSIcon.png";
  import gamePIcon from "@assets/gamePIcon.png";

  const props = defineProps({
    qrCategory: String, // 路由传递的游戏类型
  });
  const store = useStore();
  const searchGname = computed(() => store.state.searchGname);

  // 响应式数据
  const router = useRouter();
  const recommendedGames = ref([]);
  const games = ref([]);
  const platforms = ref([]);
  const levels = ref([]);
  const categories = ref([]);
  const total = ref(0);

  const searchValue = reactive({
    platform: null,
    level: null,
    category: props?.qrCategory ? [+props?.qrCategory] : [],
    sort: "default",
    page: 1,
    limit: 10,
    q: searchGname || '',
  });

  // 获取枚举数据
  const fetchPlatforms = async () => {
    const res = await axios.post("web/get_game_platforms/");
    platforms.value = res?.data?.data || [];
  };

  const fetchLevels = async () => {
    const res = await axios.post("web/members/");
    levels.value = res?.data?.data || [];
  };

  const fetchCategories = async () => {
    const res = await axios.post("web/game_categories/");
    categories.value = res?.data?.data || [];
  };

  // 获取推荐游戏
  const fetchRecommendedGames = async () => {
    const res = await axios.post("web/games-recommend/", { method: "new-rec" });
    recommendedGames.value = res?.data?.data || [];
  };

  // 获取游戏列表
  const fetchGames = async () => {
    const res = await axios.post("web/games/", {
      ...searchValue,
      platform: searchValue.platform,
      level: searchValue.level,
      category: searchValue.category,
      page: searchValue.page,
      limit: searchValue.limit,
      sort1: searchValue.sort === "default" ? "-weight" : null,
      sort2: searchValue.sort === "new" ? "-created" : null,
      sort3: searchValue.sort === "sale" ? "-sale_count" : null,
    });
    games.value = res?.data?.data?.items || [];
    total.value = res?.data?.data?.total || 0;
  };

  // 页面加载时调用
  onMounted(() => {
    fetchPlatforms();
    fetchLevels();
    fetchCategories();
    fetchRecommendedGames();
    fetchGames();
  });

  // 监听搜索条件变化
  watch(searchValue, fetchGames);

  // 修改筛选条件
  const onChangePlatform = (platform) => {
    searchValue.platform = platform;
  };

  const onChangeSort = (sort) => {
    searchValue.sort = sort;
  };

  const onPageChange = (page) => {
    searchValue.page = page;
  };

  const onPageSizeChange = (current, size) => {
    searchValue.page = current;
    searchValue.limit = size;
  };

  const onClickGame = (game) => {
    router.push(`/detail/${game.id}`);
  };

  // 获取平台图标
  const getPlatformIcon = (name) => {
    if (name === "Steam") return steamIcon;
    if (name === "Epic") return epicIcon;
    if (name === "Uplay") return ybIcon;
    return null;
  };
</script>

<style module>
  .wrap {
    padding: 23px 230px 0;
  }
  .gameList {
    display: flex;
    margin-bottom: 22px;
  }
  .gameListI {
    width: 190px;
    height: 56px;
    font-size: 24px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 35px;
    cursor: pointer;
    color: #393939;
  }
  .gameListIActive {
    background-color: #237AFF;
    color: #fff;
  }
  .gameListII {
    width: 33px;
    height: 33px;
    margin-right: 18px;
  }
  .gameListIP {
    width: 56px;
    height: 56px;
    margin-right: 9px;
  }
  .gameListIYB {
    width: 42px;
    height: 40px;
    margin-right: 12px;
  }
  .gameListIEP {
    width: 36px;
    height: 41px;
    margin-right: 19px;
  }
  .sortType {
    display: flex;
    align-items: center;
    margin-bottom: 21px;
  }
  .sortTypeI {
    font-size: 22px;
    color: #393939;
    height: 32px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    margin-right: 65px;
  }
  .sortTypeIActice {
    background-color: #F5F7FA;
    /* color: #fff; */
  }
  .sortTypeII {
    width: 17px;
    height: 9px;
    margin-left: 15px;
  }
  .topPic {
    display: flex;
    margin-bottom: 25px;
  }
  .topPicI {
    flex-shrink: 0;
    margin-right: 35px;
    cursor: pointer;
  }
  .topPicII {
    width: 264px;
    height: 123px;
    border-radius: 5px;
    margin-bottom: 9px;
  }
  .topPicGN {
    font-size: 13px;
    line-height: 19px;
    font-weight: bold;
    color: #363636;
    margin-bottom: 2px;
  }
  .topPicGE {
    line-height: 10px;
    font-size: 7px;
    color: #363636;
    margin-bottom: 10px;
  }
  .divDir {
    width: 100%;
    height: 1px;
    background-color: #F8F8F8;
  }
  .cutBgc {
    height: 12px;
    background-color: #FAFAFA;
    margin-bottom: 26px;
  }
  .contentTit {
    line-height: 35px;
    font-size: 24px;
    color: #FF870F;
    margin-bottom: 31px;
  }
  .gameRes {
    display: flex;
  }
  .gameResLeft {
    min-width: 946px;
    flex-shrink: 0;
    margin-right: 40px;
  }
  .gameResRight {
    flex-grow: 1;
    flex-shrink: 0;
    width: 475px;
    border-radius: 5px;
    padding-left: 27px;
    padding-right: 27px;
    box-shadow: 0 0 10px 0 rgba(103, 103, 103, 0.12);
  }
  .gameResItem {
    margin-bottom: 26px;
  }
  .gameResItemIn {
    display: flex;
    align-items: center;
    margin-bottom: 26px;
  }
  .gameResItemDir {
    width: 100%;
    height: 1px;
    background-color: #E3E3E3;
  }
  .gameResItemInI {
    width: 232px;
    height: 108px;
    border-radius: 5px;
    margin-right: 21px;
    cursor: pointer;
  }
  .gameResItemInfo {
    width: 234px;
    overflow: hidden;
    margin-right: 73px;
  }
  .gameResItemInfoG {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 22px;
    font-weight: bold;
    color: #363636;
    line-height: 33px;
    margin-bottom: 4px;
  }
  .gameResItemInfoE {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 20px;
    font-size: 13px;
    color: #363636;
    margin-bottom: 15px;
  }
  .gameResItemInfoT {
    display: flex;
  }
  .gameResItemInfoTI {
    height: 23px;
    line-height: 23px;
    padding: 0 16px;
    font-size: 13px;
    border-radius: 4px;
    color: #58595A;
    background-color: #E3E3E3;
  }
  .gameResItemInP {
    align-self: flex-start;
    width: 100px;
    height: 29px;
    line-height: 29px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 20px;
    font-weight: bold;
    color: #363636;
    margin-top: 17px;
    margin-right: 96px;
  }
  .gameResItemInPrice {
    height: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 28px;
  }
  .gameResItemInPriceB {
    height: 26px;
    display: flex;
    align-items: center;
  }
  .gameResItemInPriceI {
    width: 71px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    font-size: 16px;
    color: #934C05;
    background-image: url('../../assets/jhbgImg.png');
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 9px;
  }
  .gameResItemInPriceP {
    font-size: 28px;
    font-weight: bold;
    color: #FF3636;
  }
  .gameResItemInPriceBL {
    font-size: 18px;
    color: #E3E3E3;
    text-decoration: line-through;
    margin-left: 12px;
    margin-right: 37px;
  }
  .gameResItemInPriceBR {
    font-size: 18px;
    color: #07B200;
    font-weight: bold;
  }
</style>
<style>
  .gameResRight .ant-collapse-borderless {
    background-color: #fff !important;
  }
  .gameResRight .ant-collapse-header {
    align-items: center !important;
    padding: 28px 0 21px !important;
    border-bottom: 2px solid #EAEAEA;
  }
  .gameResRight .ant-collapse-header-text {
    font-size: 26px !important;
    color: rgba(0, 0, 0, .85) !important;
  }
  .gameResRight .ant-collapse-header .ant-collapse-arrow {
    font-size: 22px !important;
  }
  .gameResRight .ant-collapse-content-box {
    padding: 0 !important;
    margin-top: 30px !important;
  }
  .gameResRight .ant-collapse-item {
    border-bottom: none !important;
  }
  .gameResRight .ant-radio-wrapper, .gameResRight .ant-checkbox-wrapper {
    font-size: 23px !important;
    color: rgba(0, 0, 0, .85) !important;
    margin-bottom: 30px !important;
  }
</style>
