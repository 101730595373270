import axios from '@/axios';

const userModule = {
  state: () => ({
    user: localStorage.getItem('user_info') ? JSON.parse(localStorage.getItem('user_info')) : {},
  }),
  getters: {
    getUser: state => state.user,
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
      if (user) {
        localStorage.setItem('user_info', JSON.stringify(user));
      } else {
        localStorage.removeItem('user_info');
      }
    },
  },
  actions: {
    async fetchUserInfo({ commit }) {
      try {
        // 请求用户信息的 API
        const userInfoResponse = await axios.post('web/user_info/');
        const userInfo = userInfoResponse.data.data;

        // 更新 Vuex 状态
        commit('SET_USER', userInfo);
        commit('SET_AUTHENTICATED', true, { root: true });
      } catch (error) {
        console.error('Failed to fetch user info:', error);
        // // 如果请求失败，可以在这里处理错误逻辑，例如设置用户未认证
        // commit('SET_USER', null);
        // commit('SET_AUTHENTICATED', false);
      }
    }
  }
};

export default userModule;
