<!-- 我的游戏 -->
<template>
  <div>
    <div :class="$style.wrap">
      <div :class="$style.content">
        <MyGameLib />
        <div :class="$style.conDir"></div>
        <MyGameRight />
      </div>
      <Recommend />
      <div :class="$style.rentWrap">
        <img :class="$style.rentIcon" :src="listIcon" alt="" />
        <div>租用记录</div>
      </div>
      <a-table
        :columns="columns"
        :row-key="record => record.id"
        :data-source="dataSource?.list || []"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
      />
    </div>
  </div>
</template>

<script setup>
  import { computed } from "vue";
  import axios from "@/axios";
  import { usePagination } from 'vue-request';

  import Recommend from '@/views/homeView/Recommend.vue';
  import MyGameLib from '@components/MyGameLib.vue';
  import MyGameRight from '@components/MyGameRight.vue';
  import listIcon from "@assets/listIcon.png";

  const columns = [
    {
      title: '游戏名称',
      dataIndex: 'gname',
      key: 'gname',
    },
    {
      title: '级别',
      dataIndex: 'level',
      key: 'level',
    },
    {
      title: '游戏平台',
      dataIndex: 'pingtai',
      key: 'pingtai',
    },
    {
      title: '游戏账号',
      dataIndex: 'saccount',
      key: 'saccount',
    },
    {
      title: '开始时间',
      dataIndex: 'stime',
      key: 'stime',
    },
    {
      title: '结束时间',
      dataIndex: 'etime',
      key: 'etime',
    },
    {
      title: '是否空闲',
      dataIndex: 'is_kongxian',
      key: 'is_kongxian',
      customRender: ({ text }) => {
        return text ? '是' : '否'
      }
    },
  ];

  // 获取租用记录
  const queryRentData = async (params) => {
    try {
      const limit = Number(params?.limit) || 10;
      const page = Number(params?.page) || 1;
      // 发送 POST 请求，获取历史租用记录
      const res = await axios.post('web/history_rent_no/', { limit, page });
      return res;
    } catch (error) {
      console.error('Error fetching rent data:', error);
      throw error;
    }
  };

  // 使用分页 hook 获取数据
  const {
    data: dataSource,  // 表格数据
    run,               // 触发数据请求
    loading,           // 加载状态
    current,           // 当前页码
    pageSize,          // 每页条数
    total,
  } = usePagination(queryRentData, {
    formatResult: (res) => ({
      list: res?.data?.data?.items || [],
      total: res?.data?.data?.total || 0,
    }),
    pagination: {
      currentKey: 'page',
      pageSizeKey: 'limit',
    },
  });

  const pagination = computed(() => ({
    total: total.value,
    current: current.value,
    pageSize: pageSize.value,
    showTotal: total => `共 ${total} 条`,
  }));

  const handleTableChange = (
    pag,
    filters,
  ) => {
    run({
      limit: pag.pageSize,
      page: pag?.current,
      ...filters,
    });
  };
</script>

<style module>
  .wrap {
    padding: 23px 230px 0;
  }
  .content {
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(103, 103, 103, .12);
    display: flex;
    padding: 28px 0;
    margin-bottom: 20px;
  }
  .conDir {
    border-right: 1px solid #E8E8E8;
    margin-left: 26px;
    margin-right: 22px;
  }
  .rentWrap {
    display: flex;
    align-items: center;
    font-size: 28px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 32px;
  }
  .rentIcon {
    width: 32px;
    margin-right: 18px;
  }
</style>
