<template>
  <div>
    <a-modal
      :open="isOpen"
      title="绑定支付宝账号"
      @ok="handleOk"
      @cancel="handleCancel"
      cancel-text="取消"
      ok-text="确认"
      :maskClosable="false"
      :confirmLoading="isLoading"
      :destroyOnClose="true"
    >
      <a-form
        :model="formState"
        name="basic"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
        autocomplete="off"
        style="margin-top: 24px"
      >
        <a-form-item
          label="支付宝账号"
          name="alipay_account"
          v-bind="validateInfos.alipay_account"
        >
          <a-input v-model:value="formState.alipay_account" />
        </a-form-item>
        <a-form-item
          label="支付宝绑定姓名"
          name="alipay_name"
          v-bind="validateInfos.alipay_name"
        >
          <a-input v-model:value="formState.alipay_name" />
        </a-form-item>
        <a-form-item label="图片验证码" name="captcha" v-bind="validateInfos.captcha">
          <a-input v-model:value="formState.captcha">
            <template #prefix>
              <PictureOutlined class="site-form-item-icon" />
            </template>
          </a-input>
          <img
            :src="captchaUrl"
            @click="refreshCaptcha"
            :class="$style.captchaImg"
          />
        </a-form-item>
        <a-form-item
          label="短信验证码"
          name="sms_code"
          v-bind="validateInfos.sms_code"
        >
          <a-input v-model:value="formState.sms_code">
          </a-input>
          <a-button @click="getSmsCode" :disabled="smsButtonDisabled" style="margin-top: 10px">{{
            smsButtonText
          }}</a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script setup>
import { onMounted, reactive, ref, defineProps } from "vue";
import { UserOutlined, LockOutlined, PictureOutlined } from '@ant-design/icons-vue';
import { Form, message } from "ant-design-vue";
import axios from "@/axios";
import axios_blob from '@/axios_blob';

const { isOpen, user } = defineProps({
  isOpen: Boolean,
  user: Object
});
const emit = defineEmits(["setIsOpen"]);
const useForm = Form.useForm;
const isLoading = ref(false);
const captchaUrl = ref("");
const smsButtonDisabled = ref(false);
const smsButtonText = ref('获取验证码');

const refreshCaptcha = async () => {
  try {
    const response = await axios_blob.get(
      `userauth/get_image_captcha/?source=login&time=${Date.now()}`
    );
    // 创建一个URL对象表示图片的URL
    captchaUrl.value = URL.createObjectURL(response.data);
  } catch (error) {
    console.error("获取验证码失败:", error);
  }
};
// 页面加载时获取验证码
onMounted(refreshCaptcha);

// Function to get SMS code
const getSmsCode = async () => {
  if (!formState.captcha || !formState.alipay_account) {
    message.error('请先输入图片验证码或手机号');
    return;
  }
  try {
    const response = await axios.post('userauth/get_code_by_authmethod/', {
      captcha: formState.captcha,
    });
    if (response.data.status == 10000) {
      smsButtonDisabled.value = true;
      smsButtonText.value = '120秒后重新获取';
      let countdown = 120;
      const interval = setInterval(() => {
        countdown -= 1;
        smsButtonText.value = `${countdown}秒后重新获取`;
        if (countdown <= 0) {
          clearInterval(interval);
          smsButtonDisabled.value = false;
          smsButtonText.value = '获取验证码';
        }
      }, 1000);
    } else {
      refreshCaptcha();
    }
  } catch (error) {
    console.error(error);
    refreshCaptcha();
  }
};

const formState = reactive({
  alipay_account: "",
  alipay_name: "",
  captcha: "",
  sms_code: "",
});
const rulesRef = reactive({
  alipay_account: [
    {
      required: true,
      message: "请输入支付宝账号",
    },
  ],
  alipay_name: [
    {
      required: true,
      message: "请输入支付宝绑定姓名",
    },
  ],
  captcha: [
    {
      required: true,
      message: "请输入图片验证码",
    },
  ],
  sms_code: [
    {
      required: true,
      message: "请输入短信验证码",
    },
  ],
});

const { validate, validateInfos, resetFields } = useForm(formState, rulesRef);

const handleOk = async () => {
  const values = await validate();
  isLoading.value = true;
  const res = await axios.post("web/bind_alipay_account/", values).catch(() => {});
  isLoading.value = false;
  if (res?.data?.status === 10000) {
    resetFields();
    emit("setIsOpen", false);
    return;
  }
  refreshCaptcha();
};

const handleCancel = async () => {
  resetFields();
  emit("setIsOpen", false);
};
</script>
<style module>
  .captchaImg {
    cursor: pointer;
    margin-top: 10px;
  }
</style>