<template>
  <div>
    <router-view v-if="$route.meta.isShowLayout" />
    <div v-else>
      <div class="content-header">
        <Header />
        <NavBar />
      </div>
      <div class="content-wrapper">
        <a-config-provider :locale="zhCN">
          <router-view />
        </a-config-provider>
        <!-- <LayoutFooter /> -->
        <CustomerService />
      </div>
      <div class="footer-wrapper">
        <LayoutFooter />
      </div>
      
    </div>
    <RealNameModal />
    <LoginRegModal />
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import NavBar from './views/layout/NavBar.vue';
import LayoutFooter from './views/layout/LayoutFooter.vue';
import CustomerService from './components/CustomerService.vue';
import RealNameModal from './components/RealNameModal.vue';
import LoginRegModal from './components/LoginRegModal.vue';
import Header from './views/layout/Header.vue';

// 在组件挂载时执行
onMounted(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const invitationCode = urlParams.get('user_invitation_code');

  if (invitationCode) {
    console.log('user_invitation_code add', invitationCode)
    localStorage.setItem('user_invitation_code', invitationCode);
  }
});
</script>

<style>
  /* 全局样式 */
  body,
  html {
    margin: 0;
    padding: 0;
  }
  .content-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }
  .content-wrapper {
    width: 100%;
    min-height: 150vh;
    margin-top: 90px;
    padding-bottom: 93px;
    /* transform: scale(0.7);
    transform-origin: 50% 0; */
  }

  /* 大屏幕上的最大宽度 */
  /* @media (min-width: 1200px) {
    .content-wrapper {
      max-width: 1350px; 
    }
  } */

  /* 小屏幕上的最大宽度百分比 */
  /* @media (max-width: 1199px) {
    .content-wrapper {
      max-width: 95%; 
    }
  } */
  .ant-menu-horizontal {
    border-bottom: none !important;
  }
</style>
<style scoped>
  :deep .ant-table-wrapper .ant-table-thead >tr>th {
    background-color: #F7F7F7;
  }
  :deep .ant-table-wrapper .ant-table-tbody >tr >td {
    background-color: #FCFCFC;
  }
</style>