<template>
  <div :class="$style.hotWrap">
    <div :class="$style.hot">
      <div :class="$style.header">
        <div :class="$style.left">
          <img :class="$style.hotIocn" :src="hotIcon" alt="" />
          <div>热门游戏</div>
        </div>
        <div :class="$style.right" @click="onClickMore">
          <div>更多</div>
          <img :class="$style.rightIcon" :src="rightIcon" alt="" />
        </div>
      </div>
      <a-carousel
        :dots="false"
        arrows
        v-if="banners.length > 0"
      >
        <template #prevArrow>
          <div :class="[$style.customArrow, $style.leftArrow]">
            <img :class="$style.leftArrowImg" :src="leftImg" alt="" />
          </div>
        </template>
        <template #nextArrow>
          <div :class="[$style.customArrow, $style.rightArrow]">
            <img :class="$style.rightArrowImg" :src="rightImg" alt="" />
          </div>
        </template>
        <div v-for="item in banners" :class="$style.bannerCard">
          <div :class="$style.cardGrid" v-for="cur in item" @click="onClickGame(cur)">
            <img
              :src="getFullImageUrl(cur?.img)"
              :class="$style.cardGridImg"
              alt=""
            />
            <div :class="$style.cardGridCon">
              <div :class="$style.cardGridTitle">{{cur?.gname}}</div>
              <div :class="$style.cardGridTitleE">{{cur?.ename}}</div>
              <div :class="$style.cardGridMoney">¥{{cur?.sku_price_info?.show_price_info?.use_dkj_price}}</div>
            </div>
          </div>
        </div>
      </a-carousel>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed, onMounted } from "vue";
  import { useRouter } from 'vue-router'
  import axios from "@/axios";
  import { getFullImageUrl } from "@/util/utils.js";
  import hotIcon from "@assets/hotIcon.png";
  import rightIcon from "@assets/rightIcon.png";
  import rightImg from "@assets/rightImg.png";
  import leftImg from "@assets/leftImg.png";

  const router = useRouter();
  const games = ref([]);

  onMounted(async () => {
    const res = await axios.post("web/games-recommend/", { method: 'hot-rec' }).catch(() => {});
    if (res?.data?.status === 10000) {
      games.value = res?.data?.data || [];
    }
  });

  const banners = computed(() => {
    const arr = [];
    const step = Math.ceil(games.value?.length / 8);
    for(let i = 0; i < step; i++) {
      arr.push(games.value?.slice(i * 8, (i + 1) * 8))
    }
    return arr;
  })

  // 点击更多
  const onClickMore = () => {
    router.push(`/gameStore?category=44`);
  }

  // 点击游戏图片
  const onClickGame = (current) => {
    router.push(`/detail/${current?.id || undefined}`);
  }
</script>

<style module>
  .hotWrap {
    padding: 0 230px;
    position: relative;
    top: -300px;
    margin-bottom: -285px;
  }
  .hot {
    width: 1457px;
    padding: 22px 11px 22px 23px;
    background: linear-gradient(rgba(248,248,248,.2), rgba(255,255,255,1));
    border-radius: 5px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 27px;
  }
  .left {
    display: flex;
    align-items: center;
    font-size: 28px;
    color: #333333;
    font-weight: bold;
  }
  .hotIocn {
    width: 34px;
    margin-right: 20px;
  }
  .right {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #3B3B3B;
    cursor: pointer;
    margin-right: 12px;
  }
  .rightIcon {
    width: 13px;
    margin-left: 14px;
  }
  .bannerCard {
    width: 1424px !important;
    display: flex !important;
    flex-wrap: wrap;
  }
  .cardGrid {
    width: 346px;
    height: 162px;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;
  }
  .cardGridImg {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
  .cardGridCon {
    position: absolute;
    left: 17px;
    bottom: 13px;
  }
  .cardGridTitle {
    font-size: 18px;
    line-height: 18px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 8px;
  }
  .cardGridTitleE {
    font-size: 11px;
    line-height: 11px;
    color: #fff;
    margin-bottom: 8px;
  }
  .cardGridMoney {
    font-size: 15px;
    line-height: 15px;
    color: #FFE202;
    font-weight: bold;
  }
  .customArrow {
    width: 39px !important;
    height: 39px !important;
    bottom: -52px !important;
    top: auto !important;
    left: 50% !important;
  }
  .leftArrow {
    transform: translate(-70px);
  }
  .rightArrow {
    transform: translate(20px);
  }
  .leftArrowImg {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  .rightArrowImg {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
</style>
