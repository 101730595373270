// 认证弹窗
const authModule = {
    namespaced: true,  // 使用命名空间
    state: () => ({
      isLoginModalVisible: false,
    }),
    mutations: {
      SHOW_LOGIN_MODAL(state) {
        state.isLoginModalVisible = true;
      },
      HIDE_LOGIN_MODAL(state) {
        state.isLoginModalVisible = false;
      },
    },
    actions: {
      showLoginModal({ commit }) {
        console.log(78798798, commit)
        commit('SHOW_LOGIN_MODAL');
      },
      hideLoginModal({ commit }) {
        commit('HIDE_LOGIN_MODAL');
      },
    }
  };
  
  export default authModule;
  