<template>
  <div :class="$style.offersWrap">
    <div :class="$style.left">
      <div :class="$style.leftHeader">
        <div :class="$style.leftHeaderLeft">
          <img :class="$style.leftIocn" :src="offersLeftIcon" alt="" />
          <div>特惠推送</div>
        </div>
        <div :class="$style.leftHeaderRight" @click="onClickLeftMore">
          <div>更多</div>
          <img :class="$style.leftMore" :src="rightIcon" alt="" />
        </div>
      </div>
      <div :class="$style.leftContent">
        <div v-for="item in leftGames" :class="$style.leftContentItem">
          <Image
            :class="$style.leftContentItemL"
            :src="getFullImageUrl(item?.game_icon_img)"
            :preview="false"
            @click="onClickLeftItem(item)"
          />
          <div :class="$style.leftContentItemR">
            <a-tooltip>
              <template #title>{{item?.gname || '-'}}</template>
              <div :class="$style.leftContentItemRT">{{item?.gname || '-'}}</div>
            </a-tooltip>
            <div :class="$style.leftContentItemRTT">券后价格</div>
            <div :class="$style.leftContentItemRTP">
              <div :class="$style.leftContentItemRTPS">¥{{item?.sku_price_info?.show_price_info?.use_dkj_price || '-'}}</div>
              <div :class="$style.leftContentItemRTPP">¥{{item?.sku_price_info?.show_price_info?.price || '-'}}</div>
            </div>
            <a-button :class="$style.leftContentItemRTB" type="primary" shape="round" @click="openSkuPaymentV2(item)">
              立即购买
            </a-button>
          </div>
        </div>
      </div>
    </div>
    <div :class="$style.right">
      <div :class="$style.rightHeader">
        <div :class="$style.rightHeaderLeft">
          <img :class="$style.rightIocn" :src="offersRightIcon" alt="" />
          <div>精品推荐</div>
        </div>
        <div :class="$style.rightHeaderRight" @click="onClickRightMore">
          <div>更多</div>
          <img :class="$style.rightMore" :src="rightIcon" alt="" />
        </div>
      </div>
      <div :class="$style.rightContent">
        <div
          v-for="(item, idx) in rightGames"
          :class="[
            $style.rightContentItem,
            (rightGames?.length <= 12) && (idx === 9 || idx === 10 || idx === 11) && $style.marBtnem
          ]"
          @click="onClickRightItem(item)"
        >
          <Image
            :class="$style.rightContentItemI"
            :src="getFullImageUrl(item?.img)"
            :preview="false"
          />
          <div :class="$style.rightContentItemC">
            <a-tooltip>
              <template #title>{{item?.gname || '-'}}</template>
              <div :class="$style.rightContentItemCL">{{item?.gname || '-'}}</div>
            </a-tooltip>
            <div :class="$style.rightContentItemCR">{{item?.categories?.[0] || '-'}}</div>
          </div>
          <div :class="$style.rightContentItemPT">券后价格</div>
          <div :class="$style.rightContentItemP">
            <div :class="$style.rightContentItemPS">¥{{item?.sku_price_info?.show_price_info?.use_dkj_price || '-'}}</div>
            <div :class="$style.rightContentItemPP">永久版原价：¥{{item?.sku_price_info?.show_price_info?.price || '-'}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <PaymentModal
    :isOpen="isOpen"
    :isOrderOpen="isOrderOpen"
    :curSku="curSku"
    :paymentType="paymentType"
    :orderInfo="orderInfo"
    @setIsOpen="(value) => (isOpen = value)"
    @setIsOrderOpen="(value) => (isOrderOpen = value)"
  />

</template>

<script setup>
  import { ref, onMounted } from "vue";
  import { useRouter } from 'vue-router'
  import Image from "@components/Image.vue";
  import axios from "@/axios";
  import { getFullImageUrl } from "@/util/utils.js";
  import offersLeftIcon from "@assets/offersLeftIcon.png";
  import offersRightIcon from "@assets/offersRightIcon.png";
  import rightIcon from "@assets/rightIcon.png";

  const router = useRouter();
  const leftGames = ref([]);
  const rightGames = ref([]);

  import PaymentModal from "@/components/BuyPlanModalv3.vue";

  // 模态框状态
  const isOpen = ref(false);
  const isOrderOpen = ref(false);
  const paymentType = ref(0);
  const curSku = ref(null);
  const orderInfo = ref(null);
  // 模拟直接支付
  const openSkuPaymentV2 = (item) => {
    paymentType.value = 4;
    orderInfo.value = {
      realPrice: item?.sku_price_info?.show_price_info?.use_dkj_price,
      Price: item?.sku_price_info?.show_price_info?.price,
      deduction: item?.sku_price_info?.show_price_info?.use_dkj_amount,
      time_remark: item?.sku_price_info?.enum_values,
      gname: item?.sku_price_info?.gname,
    };
    isOrderOpen.value = true;
  };


  onMounted(async () => {
    const res = await axios.post("web/games-recommend/", { method: 'off-rec' }).catch(() => {});
    if (res?.data?.status === 10000) {
      leftGames.value = res?.data?.data || [];
    }
  });

  onMounted(async () => {
    const res = await axios.post("web/games-recommend/", { method: 'premium-rec' }).catch(() => {});
    if (res?.data?.status === 10000) {
      rightGames.value = res?.data?.data || [];
    }
  });

  // 左侧点击更多
  const onClickLeftMore = () => {
    router.push(`/gameStore?category=45`);
  }
  // 右侧点击更多
  const onClickRightMore = () => {
    router.push(`/gameStore`);
  }
  // 点击特惠推送item图片
  const onClickLeftItem = (cur) => {
    router.push(`/detail/${cur?.id || undefined}`);
  }
  // 点击精品推荐item
  const onClickRightItem = (cur) => {
    router.push(`/detail/${cur?.id || undefined}`);
  }
</script>

<style module>
  .offersWrap {
    padding: 62px 230px 70px 230px;
    display: flex;
  }
  .left {
    flex-shrink: 0;
    width: 382px;
  }
  .leftHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 33px;
  }
  .leftHeaderLeft {
    display: flex;
    align-items: center;
    font-size: 28px;
    color: #333333;
    font-weight: bold;
  }
  .leftHeaderRight {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #3B3B3B;
    cursor: pointer;
  }
  .leftIocn {
    width: 34px;
    margin-right: 16px;
  }
  .leftMore {
    width: 13px;
    margin-left: 14px;
  }
  .leftContent {
    width: 100%;
    height: 1286px;
    overflow-y: auto;
    overflow-x: hidden;
    border-right: 1px solid #D0D0D0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .leftContent::-webkit-scrollbar {
    display: none;
  }
  .leftContentItem {
    display: flex;
    margin-bottom: 43px;
  }
  .leftContentItem:last-child {
    margin-bottom: 0;
  }
  .leftContentItemL {
    width: 185px !important;
    border-radius: 5px;
    cursor: pointer;
  }
  .leftContentItemR {
    margin-left: 23px;
    padding: 7px 5px 0 7px;
  }
  .leftContentItemRT {
    font-size: 23px;
    color: #2E2E2E;
    font-weight: bold;
    margin-bottom: 22px;
    width: 160px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .leftContentItemRTT {
    font-size: 17px;
    color: #2E2E2E;
    margin-bottom: 18px;
  }
  .leftContentItemRTP {
    display: flex;
    align-items: flex-end;
    margin-bottom: 16px;
  }
  .leftContentItemRTPS{
    font-size: 22px;
    height: 22px;
    color: #FF5B1F;
    font-weight: bold;
    margin-right: 17px;
  }
  .leftContentItemRTPP {
    font-size: 15px;
    height: 15px;
    color: #BCBCBC;
    text-decoration: line-through solid #BCBCBC;
  }
  .leftContentItemRTB {
    width: 113px;
    height: 37px;
    font-size: 17px;
    background-color: rgba(245, 184, 18, 1);
  }
  .leftContentItemRTB:hover {
    background-color: rgba(245, 184, 18, .7) !important;
  }
  .right {
    flex: auto;
    margin-left: 30px;
  }
  .rightHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 33px;
    margin-left: 4px;
  }
  .rightHeaderLeft {
    display: flex;
    align-items: center;
    font-size: 28px;
    color: #333333;
    font-weight: bold;
  }
  .rightHeaderRight {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #3B3B3B;
    cursor: pointer;
  }
  .rightIocn {
    width: 36px;
    margin-right: 16px;
  }
  .rightMore {
    width: 13px;
    margin-left: 14px;
  }
  .rightContent {
    width: 1065px;
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    height: 1286px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 4px;
  }
  .rightContent::-webkit-scrollbar {
    display: none;
  }
  .rightContentItem {
    width: 329px;
    height: 300px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 4px 0 rgba(0,0,0,.08);
    overflow: hidden;
    margin-bottom: 26px;
    margin-right: 26px;
    cursor: pointer;
  }
  .rightContentItem:nth-child(3n) {
    margin-right: 0;
  }
  .rightContentItemI {
    width: 100%;
    height: 140px;
    border-radius: 5px;
    margin-bottom: 14px;
  }
  .rightContentItemC {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 17px;
    border-bottom: 1px solid #D9D9D9;
  }
  .rightContentItemCL {
    font-size: 19px;
    font-weight: bold;
    color: #212121;
    width: 230px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .rightContentItemCR {
    font-size: 16px;
    color: #212121;
    width: 65px;
    flex-shrink: 0;
    text-align: end;
  }
  .rightContentItemPT {
    font-size: 17px;
    color: #2E2E2E;
    margin-bottom: 11px;
  }
  .rightContentItemP {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .rightContentItemPS{
    font-size: 22px;
    height: 22px;
    color: #FF5B1F;
    font-weight: bold;
    margin-right: 17px;
  }
  .rightContentItemPP {
    font-size: 15px;
    height: 15px;
    color: #BCBCBC;
    text-decoration: line-through solid #BCBCBC;
  }
  .marBtnem {
    margin-bottom: 0;
  }
</style>
