<template>
  <a-row type="flex" justify="center" align="middle" style="height: 100vh;">
    <a-col>
      <a-card :title="$t('login.title')">
        <a-form
          :model="formState"
          name="normal_login"
          class="login-form"
          @finish="onFinish"
          @finishFailed="onFinishFailed"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-form-item
            :label="$t('login.username')"
            name="username"
            :rules="[{ required: true, message: $t('login.usernameRequired') }]"
          >
            <a-input v-model:value="formState.username">
              <template #prefix>
                <UserOutlined class="site-form-item-icon" />
              </template>
            </a-input>
          </a-form-item>

          <a-form-item
            :label="$t('login.password')"
            name="password"
            :rules="[{ required: true, message: $t('login.passwordRequired') }]"
          >
            <a-input-password v-model:value="formState.password">
              <template #prefix>
                <LockOutlined class="site-form-item-icon" />
              </template>
            </a-input-password>
          </a-form-item>

          <!-- Image Captcha -->
          <a-form-item
            :label="$t('login.captcha')"
            name="captcha"
            :rules="[{ required: true, message: $t('login.captchaRequired') }]"
          >
            <a-input v-model:value="formState.captcha">
              <template #prefix>
                <PictureOutlined class="site-form-item-icon" />
              </template>
            </a-input>
            <img :src="captchaUrl" @click="refreshCaptcha" class="captcha-img" />
          </a-form-item>

          <a-form-item>
            <a-form-item name="remember" no-style>
              <a-checkbox v-model:checked="formState.remember">{{ $t('login.remember') }}</a-checkbox>
            </a-form-item>
            <a class="login-form-forgot" href="" @click="onRecover">{{ $t('login.forgot') }}</a>
          </a-form-item>

          <a-form-item>
            <a-button :disabled="disabled" type="primary" html-type="submit" class="login-form-button">
              {{ $t('login.login') }}
            </a-button>
            {{ $t('login.or') }}
            <a href="/register">{{ $t('login.register') }}</a>
          </a-form-item>
        </a-form>
      </a-card>
    </a-col>
  </a-row>
</template>

<script setup>
import { reactive, computed, ref } from 'vue';
import { UserOutlined, LockOutlined, PictureOutlined } from '@ant-design/icons-vue';
import axios from '@/axios';

import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const formState = reactive({
  username: '',
  password: '',
  captcha: '',
  remember: true,
});

const store = useStore();
const router = useRouter();
const route = useRoute();

import axios_blob from '@/axios_blob';
// 定义captchaUrl为响应式引用
const captchaUrl = ref('');

const refreshCaptcha = async () => {
  try {
    const response = await axios_blob.get(`userauth/get_image_captcha/?source=login&time=${Date.now()}`);
    // 创建一个URL对象表示图片的URL
    captchaUrl.value = URL.createObjectURL(response.data);
  } catch (error) {
    console.error(t('login.captchaRefreshFailed'), error);
  }
};
// 页面加载时获取验证码
onMounted(refreshCaptcha);

const onFinish = async () => {
  try {
    const response = await axios.post('userauth/login/', formState);
    if (response.data.status !== 10000) {
      refreshCaptcha();
      return;
    }
    const accessToken = response.data.data.token;
    localStorage.setItem('access_token', accessToken);

    // 获取用户信息
    const userInfoResponse = await axios.post('web/user_info/');
    const userInfo = userInfoResponse.data.data;

    // 更新 Vuex 状态
    store.commit('SET_AUTHENTICATED', true);
    store.commit('SET_USER', userInfo);

    // 获取重定向路径
    const redirectPath = route.query.redirect || '/';

    // 跳转到重定向路径
    router.push(redirectPath);
  } catch (error) {
    console.error(error);
    refreshCaptcha();
  }
};

const onFinishFailed = errorInfo => {
  console.log('Failed:', errorInfo);
};

const disabled = computed(() => {
  return !(formState.username && formState.password && formState.captcha);
});

const onRecover = () => {
  router.push('/recover')
}
</script>

<style scoped>
a-card {
  margin-top: 50px;
}
#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}
.captcha-img {
  cursor: pointer;
  margin-top: 10px;
}
</style>
