<template>
  <div>
    <a-modal
      :open="isOpen"
      @cancel="handleCancel"
      :maskClosable="false"
      :destroyOnClose="true"
      width="100%"
      :wrapClassName="$style.modal"
    >
      <template #title>
        <div :class="$style.modalTitle">{{curSku?.gname ? `《${curSku?.gname}》` : '会员'}}购买方案</div>
      </template>
      <template #footer>
        <div :class="$style.modalBtn">
          <a-button key="submit" :class="$style.modalSubBtn" type="primary" :loading="loading" @click="handleBuy">立即购买</a-button>
          <!-- <a-button key="back">会员免费玩</a-button> -->
        </div>
      </template>
      <div :class="$style.title">
        <img :class="$style.titleImg" :src="trumpetIcon" />
        <div :class="$style.titleCon">永久版可永久租玩单个游戏；会员可畅玩全站3000+款游戏，价值10万+元！</div>
      </div>
      <div :class="$style.content">
        <div
          v-if="!!curSku?.sku_name"
          :class="[$style.contentI, curItem?.sku_name === curSku?.sku_name && $style.activeSku]"
          @click="setCurItem(curSku)"
        >
          <div :class="$style.contentITit">{{curSku?.sku_name || '-'}}</div>
          <div :class="$style.contentIPrice">¥{{curSku?.show_price_info?.price?.toFixed(2) || '-'}}</div>
          <div :class="$style.contentIDe">¥{{curSku?.show_price_info?.use_dkj_amount?.toFixed(2) || '-'}}</div>
          <div :class="$style.contentIFP">
            <div :class="$style.contentIFPT">¥{{curSku?.show_price_info?.use_dkj_price?.toFixed(2) || '-'}}</div>
            <div :class="$style.contentIFPB">立省¥{{curSku?.show_price_info?.use_dkj_amount?.toFixed(2) || '-'}}</div>
          </div>
        </div>
        <div
          v-for="item in vipData"
          :class="[$style.contentI, curItem?.vip_sku_value === item?.vip_sku_value && $style.activeSku]"
          @click="setCurItem(item)"
        >
          <div :class="$style.contentITit">{{item?.vip_sku_value || '-'}}</div>
          <div :class="$style.contentIPrice">¥{{item?.price?.toFixed(2) || '-'}}</div>
          <div :class="$style.contentIDe">¥{{item?.use_dkj_amount?.toFixed(2) || '-'}}</div>
          <div :class="$style.contentIFP">
            <div :class="$style.contentIFPT">¥{{item?.use_dkj_price?.toFixed(2) || '-'}}</div>
            <div :class="$style.contentIFPB">立省¥{{item?.use_dkj_amount?.toFixed(2) || '-'}}</div>
          </div>
        </div>
      </div>
    </a-modal>
    <!-- 订单信息弹窗 -->
    <a-modal
      :open="isOrderOpen"
      @cancel="handleOrderCancel"
      :maskClosable="false"
      :destroyOnClose="true"
      width="100%"
      title="订单信息"
      :wrapClassName="$style.orderModal"
      :footer="null"
    >
      <div :class="$style.orderTip">
        <div :class="$style.orderTipC">任何支付问题请右上角点击联系微信客服</div>
        <div :class="$style.orderTipR">如果支付失败，抵扣金将在5分钟内返回</div>
      </div>
      <div :class="$style.orderDir"></div>
      <!-- 会员订单展示项 -->
      <div v-if="!curItem?.sku_name">
        <div :class="$style.orderItem">
          <div :class="$style.orderLab">会员等级</div>
          <div :class="$style.orderVal">{{curItem?.vip_sku_value || '-'}}</div>
        </div>
        <div :class="$style.orderItem">
          <div :class="$style.orderLab">会员时长</div>
          <div :class="$style.orderVal">{{curItem?.time_remark || '-'}}</div>
        </div>
      </div>
      <!-- sku订单展示项 -->
      <div v-if="!!curItem?.sku_name">
        <div :class="$style.orderItem">
          <div :class="$style.orderLab">游戏名称</div>
          <div :class="$style.orderVal">{{curItem?.gname || '-'}}</div>
        </div>
        <div :class="$style.orderItem">
          <div :class="$style.orderLab">租号时长</div>
          <div :class="$style.orderVal">{{curItem?.sku_name || '-'}}</div>
        </div>
        <div :class="$style.orderItem">
          <div :class="$style.orderLab">租号时间</div>
          <div :class="$style.orderVal">以订单实际支付时间为准</div>
        </div>
        <div :class="$style.orderDirB"></div>
      </div>
      <!-- 公共展示项 -->
      <div :class="$style.orderItem">
        <div :class="$style.orderLab">订单金额</div>
        <div :class="$style.orderVal">
          ¥{{curItem?.sku_name ? curItem?.show_price_info?.price?.toFixed(2) || '-' : curItem?.price?.toFixed(2) || '-'}}
        </div>
      </div>
      <div :class="$style.orderItem">
        <div :class="$style.orderLab">使用抵扣金</div>
        <div :class="$style.orderVal">
          ¥{{curItem?.sku_name ? curItem?.show_price_info?.use_dkj_amount?.toFixed(2) || '-' : curItem?.use_dkj_amount?.toFixed(2) || '-'}}
        </div>
      </div>
      <div :class="$style.orderItem">
        <div :class="[$style.orderLab, $style.orderLabB]">实际金额</div>
        <div :class="[$style.orderVal, $style.orderValB]">
          ¥{{curItem?.sku_name ? curItem?.show_price_info?.use_dkj_price?.toFixed(2) || '-' : curItem?.use_dkj_price?.toFixed(2) || '-'}}
        </div>
      </div>
      <div :class="$style.orderDirB"></div>
      <div :class="$style.orderButT">选择支付方式</div>
      <img :class="$style.orderBuyImg" :src="alipay" @click="onClickPay(0)" />
      <img :class="$style.orderBuyImg" :src="wechat" @click="onClickPay(1)"/>
    </a-modal>
    <!-- 支付确认弹窗 -->
    <a-modal
      :open="isBuyOpen"
      @cancel="handleBuyCancel"
      :maskClosable="false"
      :destroyOnClose="true"
      width="100%"
      title="支付确认"
      :wrapClassName="$style.orderModal"
      :footer="null"
    >
      <div :class="$style.orderTip">
        <div :class="$style.orderTipC">任何支付问题请右上角点击联系微信客服</div>
        <div :class="$style.orderTipR">如果支付失败，抵扣金将在5分钟内返回</div>
      </div>
      <div :class="[$style.orderItem, $style.buyItem]">
        <div :class="$style.orderLab">支付方式：</div>
        <div :class="$style.orderVal">{{payType === 0 ? '支付宝' : '微信'}}</div>
      </div>
      <div :class="$style.payAmount">
        应付金额：<span :class="$style.payAmountP">¥{{curItem?.sku_name ? curItem?.show_price_info?.use_dkj_price?.toFixed(2) || '-' : curItem?.use_dkj_price?.toFixed(2) || '-'}}</span>
      </div>
      <div :class="$style.qrCodeUrl">
        <a-qrcode :value="qrCodeUrl" :size="qrSize" />
        <div v-if="isQrMask" :class="$style.qrCodeMask">
          <p :class="$style.qrCodeExpired">二维码已过期</p>
        </div>
      </div>
      <div :class="$style.payBtTip">
        请使用<span :class="$style.payBtTipT"> “{{payType === 0 ? '支付宝' : '微信'}}/浏览器” </span>扫一扫完成支付
      </div>
    </a-modal>
  </div>
</template>
<script setup>
  // 购买方案弹窗
  import { ref, onMounted, defineProps, watch, nextTick } from "vue";
  import axios from "@/axios";
  import { useRoute, useRouter } from 'vue-router'
  import { message } from "ant-design-vue";

  import { waitTime } from "@/util/utils.js";
  import trumpetIcon from "@assets/trumpetIcon.png";
  import alipay from "@assets/alipay.png";
  import wechat from "@assets/wechat.png";

  const route = useRoute();
  const router = useRouter();

  const loading = ref(false); // 用于控制按钮的 loading 状态
  const curItem = ref();
  const vipData = ref();
  const skuData = ref();
  const payType = ref(); // 0:支付宝  1:微信
  const isOrderOpen = ref(false);
  const isBuyOpen = ref(false);
  const qrCodeUrl = ref(null);
  const qrSize = ref();
  const isQrMask = ref(false); // 是否展示二维码过期
  let qrCount = 0;

  const props = defineProps({
    isOpen: Boolean,
    curSku: Object || undefined,
  });
  const emit = defineEmits(["setIsOpen"]);

  watch(() => props.isOpen, async (newVal) => {
    if (newVal) {
      // 请求开始时设置 loading 状态
      loading.value = true;
      try {
        // 发送请求
        const res = await axios.post("web/get_members_buy_plan/", {});
        if (res?.data?.status === 10000) {
          vipData.value = (res?.data?.data || []).slice(0, 3);
        }

        if (!route.params?.id) return;
        const res1 = await axios.post("web/skus/", { game_id: route.params?.id}).catch(() => {});
        if (res1?.data?.status === 10000) {
          skuData.value = (res1?.data?.data?.items || []).slice(0, 1);
        }
      } catch (error) {
        console.error("请求失败：", error);
      } finally {
        // 请求结束后取消 loading 状态
        loading.value = false;
      }

    }
  });

  // 默认选中父组件选择的sku，这里要重新刷新，但是都是假设取第一个
  watch(() => props.curSku, async () => {
    if (!props?.curSku) return;
    curItem.value = { ...props.curSku };
  })

  // 二维码尺寸
  onMounted(async () => {
    const long = document.documentElement.clientWidth * (256 / 1920);
    qrSize.value = long;
  });

  // 选择购买方案
  const setCurItem = (cur) => {
    curItem.value = cur;
  }

  // 点击立即购买
  const handleBuy = () => {
    if (!curItem.value || JSON.stringify(curItem.value) === '{}') {
      message.warning("请选择购买方案");
      return;
    }
    isOrderOpen.value = true;
  }

  // 创建订单
  const createOrder = async (pType) => {
    // 创建订单
    const result = await axios.post("web/orders/generate/", {
      skuId: curItem?.value?.id,
      vip_sku_value: curItem?.value?.vip_sku_value,
      payment_type: pType,
      realPrice: curItem?.value?.sku_name ? curItem?.value?.show_price_info?.use_dkj_price : curItem?.value?.use_dkj_price, // 真实支付价格
      Price: curItem?.value?.sku_name ? curItem?.value?.show_price_info?.price : curItem?.value?.price, // 原始价格
      deduction: curItem?.value?.sku_name ? curItem?.value?.show_price_info?.use_dkj_amount : curItem?.value?.use_dkj_amount, // 抵扣金使用金额
    }).catch(() => {});
    if (result?.data?.status !== 10000) return undefined;
    return result?.data?.data;
  }

  // 轮询订单信息
  const checkPaynoInfo = async (payNo, pType) => {
    // 自动轮训最大次数
    if (qrCount === 1000) {
      isQrMask.value = true;
      qrCount = 0;
      return;
    };
    // 手动关闭轮训
    if (qrCount > 1000) {
      qrCount = 0;
      return;
    };
    await waitTime(1000);
    const res = await axios.post("web/orders/query_order/", { pay_no: payNo }).catch(() => {});
    qrCount++;
    // 支付成功
    if (res?.data?.status === 10000 && res?.data?.data?.status === 100) {
      console.log('支付成功');
      qrCount = 0;
      return;
    };
    // 支付失败 || 支付超时
    if (res?.data?.status === 10000 && res?.data?.data?.status === 404) {
      const orderInfo = await createOrder(pType);
      // qrCount = 0;
      if (!orderInfo) return;
      qrCodeUrl.value = orderInfo?.url;
      checkPaynoInfo(orderInfo?.pay_no, pType);
      return;
    };
    checkPaynoInfo(payNo, pType);
  }

  // 点击支付宝||微信支付
  const onClickPay = async (pType) => {
    payType.value = pType;
    // 查询是否存在老订单
    const res = await axios.post("web/orders/check-data/", {
      skuid: curItem?.value?.id,
      vip_sku_value: curItem?.value?.vip_sku_value,
      payment_type: pType,
    }).catch(() => {});
    if (res?.data?.status !== 10000) return;
    // 存在老订单
    if(res?.data?.data?.is_exist_order) {
      isBuyOpen.value = true;
      // await nextTick();
      qrCodeUrl.value = res?.data?.data?.url;
      checkPaynoInfo(res?.data?.data?.pay_no, pType);
      return;
    }
    // 创建订单
    const orderInfo = await createOrder(pType);
    if (!orderInfo) return;
    isBuyOpen.value = true;
    // await nextTick();
    qrCodeUrl.value = orderInfo?.url;
    checkPaynoInfo(orderInfo?.pay_no, pType);
  }

  const handleCancel = async () => {
    curItem.value = { ...props.curSku };
    emit("setIsOpen", false);
  };

  const handleOrderCancel = () => {
    isOrderOpen.value = false;
  };

  const handleBuyCancel = () => {
    isBuyOpen.value = false;
    if (isQrMask.value) {
      qrCount = 0;
      isQrMask.value = false;
    } else {
      qrCount = 1000;
    }
  };
</script>

<style module>
  .modal {
    width: 1100px;
    margin: 0 auto;
  }
  .modalTitle {
    font-size: 26px;
  }
  .modalBtn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modalSubBtn {
    font-size: 25px;
    width: 200px;
    height: 58px;
    background-color: rgba(255, 73, 49, 1);
    border-radius: 5px;
    margin-right: 14px;
  }
  .modalSubBtn:hover {
    background-color: rgba(255, 73, 49, .7) !important;
  }
  .title {
    background-color: #FEF3E7;
    font-size: 21px;
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 10px;
    margin: 21px 0;
  }
  .titleImg {
    width: 25px;
    height: 25px;
    margin-right: 22px;
  }
  .content {
    display: flex;
    margin-bottom: 40px;
    justify-content: center;
  }
  .contentI {
    border: 1px solid rgba(180, 180, 180, .2);
    width: 270px;
  }
  .contentITit {
    background-color: #FAF4E2;
    color: #1A1A1A;
    font-size: 21px;
    font-weight: bold;
    height: 52px;
    line-height: 52px;
    text-align: center;
  }
  .contentIPrice {
    background-color: #FAFAFA;
    color: #646464;
    font-size: 24px;
    height: 76px;
    line-height: 76px;
    text-align: center;
  }
  .contentIDe {
    color: #F5B812;
    font-size: 24px;
    height: 52px;
    line-height: 52px;
    text-align: center;
  }
  .contentIFP {
    background-color: #FAFAFA;
    padding: 18px 0 15px 0;
  }
  .contentIFPT {
    font-size: 29px;
    color: #FF2727;
    text-align: center;
    margin-bottom: 6px;
  }
  .contentIFPB {
    font-size: 16px;
    color: #888888;
    text-align: center;
    text-decoration: line-through;
  }
  .activeSku {
    background-color: #FFFBF8;
    border: 2px solid #F56912;
  }
  .orderModal {
    width: 380px;
    margin: 0 auto;
  }
  .orderTip {
    padding: 10px;
    color: #666666;
    font-size: 14px;
    background: #FDEDDB;
  }
  .orderTipC {
    text-align: center;
  }
  .orderTipR {
    text-align: center;
    color: red;
  }
  .orderDir {
    height: 1px;
    margin: 20px 0;
    background-color: #eee;
  }
  .orderDirB {
    height: 1px;
    margin-bottom: 20px;
    background-color: #eee;
  }
  .orderItem {
    display: flex;
    margin-bottom: 14px;
    min-height: 36px;
  }
  .orderLab {
    flex-shrink: 0;
    width: 72px;
    margin-right: 10px;
    color: #666666;
    font-size: 13px;
    font-weight: 700;
  }
  .orderLabB {
    color: #FF6F00;
  }
  .orderVal {
    flex-grow: 1;
    font-size: 14px;
    word-break: break-all;
    vertical-align: middle;
  }
  .orderValB {
    color: #FF6F00;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
  }
  .orderButT {
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: 700;
    color: #333;
  }
  .orderBuyImg {
    display: block;
    height: 40px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .buyItem {
    margin: 20px 0;
  }
  .payAmount {
    height: 38px;
    line-height: 38px;
    text-align: center;
    margin-bottom: 20px;
    font-size: 14px;
    color: #333;
    background-color: #EFF0EF;
  }
  .payAmountP {
    font-weight: 700;
    color: #FF6F00;
    font-size: 16px;
  }
  .qrCodeUrl {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    position: relative;
  }
  .payBtTip {
    font-size: 14px;
    color: #333;
  }
  .payBtTipT {
    font-weight: 700;
    font-size: 13px;
    color: #FF6F00;
  }
  .qrCodeMask {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: rgba(0, 0, 0, 0.88);
    line-height: 1.5714285714285714;
    background: rgba(255, 255, 255, 0.96);
    text-align: center;
  }
</style>