<template>
  <div :class="$style.headerWrap">
    <div :class="$style.left">
      <img :class="$style.icon" :src="zuhaoguanjiaImg" alt="" @click="onClickImg"/>
      <a-menu
        class="cuMenu"
        :selectedKeys="navSelectedKeys"
        mode="horizontal"
        :items="items"
        :disabledOverflow="true"
        @click="handleClick"
      />
    </div>
    <div :class="$style.right">
      <a-input
        v-model:value="gameName"
        placeholder="游戏搜索"
        :class="$style.input"
        @pressEnter="onPressEnter"
        allow-clear
      >
        <template #suffix>
          <a-button :class="$style.inputBtn" type="primary" shape="round" @click="onClicksearch">
            搜索
          </a-button>
        </template>
      </a-input>
      <div :class="$style.cdkBtn" @click="onCdkC">CDK兑换</div>
    </div>
  </div>
</template>

<script setup>
  import { useStore } from "vuex";
  import { ref, h, useCssModule, computed } from "vue";
  import zuhaoguanjiaImg from "@assets/zuhaoguanjia.png";
  import { useRouter } from 'vue-router';

  const store = useStore();
  const styles = useCssModule();
  const router = useRouter();
  const gameName = ref('');
  
  const navSelectedKeys = computed(() => store.state.navSelectedKeys);

  const items = ref([
    {
      key: "/",
      label: h("div", { class: styles.itemLabel }, "首页"),
    },
    {
      key: "/gameStore",
      label: h("div", { class: styles.itemLabel }, "租号商城"),
    },
    {
      key: "/myMember",
      label: h("div", { class: styles.itemLabel }, "会员中心"),
    },
    {
      key: "/news/54.html",
      label: h("div", { class: styles.itemLabel }, "加入赚钱"),
    },
    {
      key: "/myGame",
      label: h("div", { class: styles.itemLabel }, "我的游戏"),
    },
    {
      key: "/rendResult",
      label: h("div", { class: styles.itemLabel }, "正在租用"),
    },
  ]);

  const onClickImg = () => {
    router.push('/');
  }

  // 切换导航
  const handleClick = (e) => {
    store.dispatch('setNavSelectedKeys', [e.key]);
    router.push(e.key);
  }

  // 游戏搜索
  const onPressEnter = () => {
    store.dispatch('setSearchGname', gameName?.value);
    router.push(`/gameStore`);
  }
  const onClicksearch = () => {
    store.dispatch('setSearchGname', gameName?.value);
    router.push(`/gameStore`);
  }

  // cdk兑换
  const onCdkC = () => {
    router.push('/cdk');
  }
</script>

<style module>
  .headerWrap {
    height: 60px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    /* padding: 0 365px; */
    padding: 0 230px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
    z-index: 1000; /* 确保菜单栏处于较高的层级，避免被其他内容覆盖 */
  }
  .icon {
    width: 185px;
    margin-right: 38px;
    cursor: pointer;
  }
  .left {
    display: flex;
    align-items: center;
  }
  .itemLabel {
    font-size: 16px;
  }
  .right {
    display: flex;
    align-items: center;
    margin-left: auto; /* 让右侧内容浮动到右边 */
  }
  .input {
    width: 295px;
    height: 36px;
    font-size: 15px;
    border-radius: 36px;
  }
  .inputBtn {
    height: 30px;
    width: 70px;
    font-size: 15px;
    padding: 0;
    padding-inline: 0 !important;
    text-align: center;
  }
  .searchIcon {
    font-size: 18px;
    color: #D3D3D3;
    cursor: pointer;
  }
  .cdkBtn {
    margin-left: 20px;
    width: 107px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    border-radius: 36px;
    cursor: pointer;
    background: linear-gradient(rgba(147, 160, 255), rgba(38, 62, 247));
  }
</style>

<style scoped>
  .cuMenu .ant-menu-item {
    padding-inline: 16px !important;
    line-height: 46px !important;
  }
  .cuMenu {
    line-height: 46px !important;
  }
  :deep .ant-input-affix-wrapper {
    background-color: #F1F1F1;
    padding: 4px 3px 4px 11px;
  }
  :deep .ant-input {
    background-color: #F1F1F1;
  }
</style>
