<template>
  <div>
    <Splide :options="mainOptions" ref="main">
      <SplideSlide v-for="(slide, idx) in images" :key="`${slide}-${idx}`">
        <img :src="getFullImageUrl(`media/${slide}`)" />
      </SplideSlide>
    </Splide>
    <Splide :options="thumbsOptions" ref="thumbs" class="splideThumb">
      <SplideSlide v-for="(slide, idx) in images" :key="`${slide}-${idx}`">
        <img :src="getFullImageUrl(`media/${slide}`)" />
      </SplideSlide>
    </Splide>
  </div>
</template>

<script setup>
import { Splide, SplideSlide, Options } from '@splidejs/vue-splide';
import { onMounted, ref } from 'vue';
import '@splidejs/vue-splide/css';
import '@splidejs/splide/css/sea-green';

import { getFullImageUrl } from "@/util/utils.js";

const mainOptions = {
  type: 'fade',
  rewind: true,
  pagination: false,
  arrows: false,
  drag: false,
  autoplay: true,
  interval: 3000,
};

const thumbsOptions = {
  type: 'slide',
  rewind: true,
  gap: '0.1rem',
  pagination: false,
  fixedWidth: 110,
  fixedHeight: 70,
  cover: true,
  focus: 'center',
  isNavigation: true,
  updateOnMove: true,
};

const main = ref();
const thumbs = ref();

const props = defineProps({
  images: Array,
});

onMounted(() => {
  const thumbsSplide = thumbs.value?.splide;

  if (thumbsSplide) {
    main.value?.sync(thumbsSplide);
  }
});
</script>
<style>
.splide {
  padding: 0;
}
.splideThumb {
  padding: 40px;
}
.splide__slide img {
  width: 100%;
  height: 305px;
  border-radius: 8px;
}
.splide--nav {
  margin-top: 0;
}
.splide__arrow svg  {
  height: 100%;
  width: 100%;
  /* fill: #DDDDDD; */
}
.splide__arrow--prev {
  height: 50px;
  width: 50px;
  left: -20px;
}
.splide__arrow--next  {
  height: 50px;
  width: 50px;
  right: -20px;
}
</style>