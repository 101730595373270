<!-- 租号成功结果页 -->
<template>
  <div :class="$style.wrap">
    <div :class="$style.top">
      <div :class="$style.topItem" v-for="item in gameData">
        <Image
          :class="$style.topItemImg"
          :src="getFullImageUrl(item?.img)"
          :preview="false"
        />
        <div :class="$style.topItemName">
          <div :class="$style.topItemNameN">
            <a-tooltip>
              <template #title>{{item?.gname || '-'}}</template>
              <div :class="$style.topItemNameNC">{{item?.gname || '-'}}</div>
            </a-tooltip>
            <div :class="$style.topItemNameNE">{{item?.ename || '-'}}</div>
          </div>
          <div :class="$style.topItemNameP">
            <div :class="$style.topItemNamePT">永久版原价</div>
            <div :class="$style.topItemNamePN">¥{{item?.sku_price_info?.show_price_info?.price || '-'}}</div>
          </div>
        </div>
        <div :class="$style.topItemDir"></div>
        <div :class="$style.topItemF">
          <div :class="$style.topItemFL">
            <div :class="$style.topItemFLD">可抵扣：¥{{item?.sku_price_info?.show_price_info?.use_dkj_amount || '-'}}</div>
            <div :class="$style.topItemFLT">新用户首单优惠后</div>
            <div :class="$style.topItemFLP">¥{{item?.sku_price_info?.show_price_info?.use_dkj_price || '-'}}</div>
          </div>
          <div :class="$style.topItemFR" @click="onClickBuy(item)">
            <div>
              <img :class="$style.topItemFRImg" :src="gwcIcon" />
              <div :class="$style.topItemFRT">立即购买</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="$style.tip">
      <img :class="$style.tipI" :src="trumpetIcon" alt="" />
      <div :class="$style.tipT">招募平台推广员，边聊天边赚钱！</div>
    </div>
    <div :class="$style.content">
      <div :class="$style.contentH">
        <div :class="$style.contentHDW" @click="onClickArc">
          <img :class="$style.contentHD" :src="cdDownIcon" />
          <div :class="$style.contentHA">存档更换</div>
        </div>
        <div :class="$style.contentHB">您可以移档，上传下载你的存档，欢迎使用！</div>
        <div :class="$style.contentHDir"></div>
        <div :class="$style.contentHCW" @click="onClickTW">
          <img :class="$style.contentHC" :src="jsqIcon" />
          <div :class="$style.contentHE">腾讯加速器</div>
        </div>
        <div :class="$style.contentHF">免费48小时兑换码：ZH888</div>
      </div>
      <a-table
        :columns="columns"
        :row-key="record => record.id"
        :data-source="dataSource?.items"
        :pagination="false"
        :loading="loading"
        :scroll="{ y: 400 }"
      >
        <template #bodyCell="{ text, column, record }">
          <template v-if="column.dataIndex === 'saccount'">
            <div v-if="text">
              <div :class="$style.itemYQ" @click="fetchYanqi(record.id)">延期/加时长</div>
              <div :class="$style.itemYH" @click="onClickSwitch(record.id)">切换账号</div>
              <div :class="$style.itemT">{{ text }}</div>
              <div :class="$style.itemCopy" @click="onCopy(text)">复制</div>
            </div>
            <div v-else>-</div>
          </template>
          <template v-if="column.dataIndex === 'spassword'">
            <div v-if="text">
              <div @click="$router.push('/news/5.html')" :class="$style.itemFDH">防顶号必看</div>
              <div :class="$style.itemYZM" @click="fetchYzma(record.id)">验证码/令牌</div>
              <div :class="$style.itemT">{{ text }}</div>
              <div :class="$style.itemCopy" @click="onCopy(text)">复制</div>
            </div>
            <div v-else>-</div>
          </template>
        </template>
      </a-table>
      <div :class="$style.contentF">
        <img :class="$style.contentFA" :src="bzwdIcon" />
        <div :class="$style.contentFB">帮助文档</div>
        <div @click="$router.push('/news/5.html')" :class="[$style.contentFFM, $style.contentFF]">
          <div :class="$style.contentFFT">防顶号说明</div>
          <img :class="$style.contentFFI" :src="dRightIcon" />
        </div>
        <div @click="$router.push('/news/57.html')" :class="[$style.contentFFM, $style.contentFF]">
          <div :class="$style.contentFFT">存档说明</div>
          <img :class="$style.contentFFI" :src="dRightIcon" />
        </div>
        <div @click="$router.push('/news/1.html')" :class="[$style.contentFFM, $style.contentFF]">
          <div :class="$style.contentFFT">Steam加好友</div>
          <img :class="$style.contentFFI" :src="dRightIcon" />
        </div>
        <div @click="$router.push('/news/1.html')" :class="$style.contentFF">
          <div :class="$style.contentFFT">免费48h加速器</div>
          <img :class="$style.contentFFI" :src="dRightIcon" />
        </div>
      </div>
    </div>
    <Recommend />
    <RentAccountDialog
      :isOpen="isRentAccountDialog"
      :pkId="rentPkId"
      :rentType="'switch'"
      @setIsOpen="setIsRentAccountDialog"
      @successCallBack="onRentAccountSuccess"
    />
  </div>
</template>

<script setup>
  import { ref, onMounted } from "vue";
  import { usePagination } from 'vue-request';
  import useClipboard from 'vue-clipboard3';
  import { message } from 'ant-design-vue';
  import { useRouter } from 'vue-router'
  import axios from "@/axios";
  import RentAccountDialog from '@components/SelectAccountModal.vue';

  import Recommend from '@/views/homeView/Recommend.vue';
  import Image from "@components/Image.vue";
  import { getFullImageUrl } from "@/util/utils.js";
  import gwcIcon from "@assets/gwcIcon.png";
  import trumpetIcon from "@assets/trumpetIcon.png";
  import cdDownIcon from "@assets/cdDownIcon.png";
  import jsqIcon from "@assets/jsqIcon.png";
  import bzwdIcon from "@assets/bzwdIcon.png";
  import dRightIcon from "@assets/dRightIcon.png";

  const { toClipboard } = useClipboard();
  const router = useRouter();
  const gameData = ref();

  const columns = [
    {
      title: '游戏名称',
      dataIndex: 'gname',
      customRender: ({ text }) => {
        return text || '-';
      }
    },
    {
      title: '级别',
      dataIndex: 'level',
      width: '10%',
      customRender: ({ text }) => {
        return text || '-';
      }
    },
    {
      title: '游戏平台',
      dataIndex: 'pingtai',
      width: '8%',
      customRender: ({ text }) => {
        return text || '-';
      }
    },
    {
      title: '账号',
      dataIndex: 'saccount',
      width: '15%',
    },
    {
      title: '密码',
      dataIndex: 'spassword',
      width: '15%',
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: '8%',
      customRender: ({ text }) => {
        return text || '-';
      }
    },
    {
      title: '结束时间',
      dataIndex: 'etime',
      width: '10%',
      customRender: ({ text }) => {
        return text || '-';
      }
    },
    {
      title: 'CDKEYY/订单号',
      dataIndex: 'cdkey',
      width: '12%',
      customRender: ({ text, record }) => {
        if (text) {
          return text;
        } else if (record?.order) {
          return record?.order;
        } else {
          return '-';
        }
      }
    },
  ];


  // 租号
  const rentPkId = ref();
  const isRentAccountDialog = ref(false);

  const setIsRentAccountDialog = (flag) => {
    isRentAccountDialog.value = flag;
  }

  const onClickSwitch = (accountId) => {
    rentPkId.value = accountId;
    isRentAccountDialog.value = true;
  }

  const onRentAccountSuccess = () => {
    // 租号成功，弹框提示弹窗
    // router.push('/rendResult');
    run()
  }

  const fetchYanqi = async (recordId) => {
      const response = await axios.post('web/account/yanqiv2/', { yqid: recordId });
        
  }

  const fetchYzma = async (recordId) => {
    const response = await axios.post('web/account/yzma/', { yzid: recordId });
    // 这里出发验证码弹窗   
  }

  // 获取新游推荐
  onMounted(async () => {
    const res = await axios.post("web/games-recommend/", { method: 'new-rec', limit: 4 }).catch(() => {});
    if (res?.data?.status === 10000) {
      gameData.value = res?.data?.data || [];
    }
  });

  // 获取租用记录
  const queryRentData = (params) => {
    return axios.post('web/rent_no/', { limit: 9999 });
  };

  const {
    data: dataSource,
    run,
    loading,
  } = usePagination(queryRentData, {
    formatResult: res => res?.data?.data,
  });

  // 点击立即购买
  const onClickBuy = (current) => {
    router.push(`/detail/${current?.id || undefined}`);
  }

  // 复制
  const onCopy = async (text) => {
    if (!text) return;
    try {
      await toClipboard(text);
      message.success('复制成功');
    } catch (e) {
      console.error(e)
    }
  }

  // 点击存档更换
  const onClickArc = () => {
    window.open('https://www.zuhaoguanjia.com/news/57.html');
  }

  const onClickTW = () => {
    window.open('https://www.tengyoujiasu.com');
  }

</script>

<style module>
  .wrap {
    padding: 23px 230px 0;
  }
  .top {
    display: flex;
    justify-content: space-between;
  }
  .topItem {
    width: 345px;
  }
  .topItemImg {
    width: 100% !important;
    height: 161px !important;
    border-radius: 5px;
    margin-bottom: 13px;
  }
  .topItemName {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    margin-right: 4px;
  }
  .topItemNameN {
    color: #363636;
  }
  .topItemNameNC {
    font-size: 17px;
    font-weight: bold;
    line-height: 25px;
    margin-bottom: 2px;
    width: 245px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .topItemNameNE {
    font-size: 10px;
    line-height: 15px;
  }
  .topItemNameP {
    flex-shrink: 0;
  }
  .topItemNamePT {
    width: 86px;
    height: 19px;
    line-height: 19px;
    text-align: center;
    font-size: 12px;
    color: #989898;
    background-color: rgba(173, 173, 173, 0.22);
    border-radius: 19px;
    margin-bottom: 1px;
  }
  .topItemNamePN {
    width: 86px;
    text-align: center;
    font-size: 13px;
    line-height: 20px;
    color: #939393;
    text-decoration: line-through solid #939393;
  }
  .topItemDir {
    width: 100%;
    height: 1px;
    background-color: #F8F8F8;
    margin-bottom: 14px;
  }
  .topItemF {
    display: flex;
    justify-content: space-between;
  }
  .topItemFLD {
    width: 115px;
    height: 19px;
    background-image: url('../../assets/dkBgc.png');
    background-repeat: no-repeat;
    background-size: cover;
    line-height: 19px;
    font-size: 12px;
    color: #934C05;
    padding-left: 8px;
    margin-bottom: 6px;
  }
  .topItemFLT {
    font-size: 13px;
    height: 19px;
    color: #363636;
    line-height: 19px;
    font-weight: bold;
  }
  .topItemFLP {
    font-size: 17px;
    color: #FF3636;
    font-weight: bold;
    height: 25px;
    line-height: 25px;
  }
  .topItemFR {
    width: 103px;
    height: 66px;
    background-image: url('../../assets/gmBgc.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
  }
  .topItemFRImg {
    width: 23px;
  }
  .topItemFRT {
    margin-top: 3px;
    font-size: 15px;
    height: 21px;
    line-height: 21px;
  }
  .tip {
    display: flex;
    align-items: center;
    margin: 32px 0 26px;
    height: 50px;
    border-radius: 12px;
    background-color: rgba(255, 135, 15, 0.10);
    padding-left: 19px;
  }
  .tipI {
    width: 25px;
    height: 25px;
    margin-right: 15px;
  }
  .tipT {
    font-size: 22px;
    color: #FF3B3B;
    font-weight: bold;
  }
  .content {
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(103, 103, 103, .12);
    padding: 21px 0 17px;
    margin-bottom: 33px;
  }
  .contentH {
    display: flex;
    align-items: center;
    padding-left: 22px;
    font-size: 22px;
    margin-bottom: 25px;
  }
  .contentHDW {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .contentHD {
    width: 23px;
    height: 23px;
    margin-right: 19px;
  }
  .contentHA {
    color: #767676;
    line-height: 33px;
    margin-right: 34px;
  }
  .contentHB {
    color: #000;
    line-height: 33px;
    margin-right: 88px;
  }
  .contentHDir {
    height: 40px;
    width: 1px;
    background-color: #E3E3E3;
    margin-right: 74px;
  }
  .contentHCW {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .contentHC {
    width: 23px;
    height: 23px;
    margin-right: 28px;
  }
  .contentHE {
    color: #767676;
    line-height: 33px;
    margin-right: 55px;
  }
  .contentHF {
    color: #000;
    line-height: 33px;
  }
  .itemYQ {
    width: 123px;
    height: 32px;
    line-height: 30px;
    text-align: center;
    font-size: 19px;
    color: #FF870F;
    border: 1px solid #FF870F;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 21px;
  }
  .itemYH {
    width: 123px;
    height: 32px;
    line-height: 30px;
    text-align: center;
    font-size: 19px;
    color: #237AFF;
    border: 1px solid #237AFF;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 17px;
  }
  .itemT {
    font-size: 20px;
    line-height: 30px;
    color: #333333;
    margin-bottom: 17px;
  }
  .itemCopy {
    width: 123px;
    height: 32px;
    line-height: 30px;
    text-align: center;
    font-size: 19px;
    color: #fff;
    background-color: #197AFF;
    border-radius: 4px;
    cursor: pointer;
  }
  .itemFDH {
    width: 123px;
    height: 32px;
    line-height: 30px;
    text-align: center;
    font-size: 19px;
    color: #fff;
    background-color: #FF5A5A;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 21px;
  }
  .itemYZM {
    width: 123px;
    height: 32px;
    line-height: 30px;
    text-align: center;
    font-size: 19px;
    color: #fff;
    background-color: #F5B812;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 17px;
  }
  .contentF {
    display: flex;
    align-items: center;
    padding-top: 19px;
    padding-left: 28px;
    font-size: 22px;
    border-top: 1px solid #E3E3E3;
  }
  .contentFA {
    width: 23px;
    height: 23px;
    margin-right: 23px;
  }
  .contentFB {
    color: #767676;
    margin-right: 36px;
  }
  .contentFFM {
    margin-right: 182px;
  }
  .contentFF {
    display: flex;
    align-items: center;
    color: #237AFF;
    cursor: pointer;
  }
  .contentFFT {
    margin-right: 19px;
  }
  .contentFFI {
    width: 17px;
    height: 15px;
  }
</style>
