<!-- 详情展示表格 -->
<template>
  <div :class="$style.wrap">
    <div :class="$style.detailWrap">
      <div :class="$style.detailLeft">
        <img :class="$style.detailIcon" :src="leftIcon" alt="" />
        <div>{{title || '-'}}</div>
      </div>
      <div :class="$style.detailRight">
        <slot name="topOperations"></slot>
      </div>
    </div>
    <a-table
      :columns="columns"
      :row-key="record => record.id"
      :data-source="dataSource?.items"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
    >
    <template #bodyCell="{ column, text, record }">
      <template v-if="column.dataIndex === 'operation'">
        <slot name="operationBtn" :record="record"></slot>
      </template>
      <template v-if="column.dataIndex === 'cknb'">
        {{text}}
        <slot name="cdkeyCopy" :text="text"></slot>
      </template>
    </template>
    </a-table>
  </div>
</template>

<script setup>
  import { computed, onMounted } from "vue";
  import { usePagination } from 'vue-request';

  const props = defineProps({
    leftIcon: String, // 头部左侧图标
    title: String, // 头部标题
    columns: Array,
    fetchData: Function, // 数据请求
    getReload: Function, // 获取表格刷新run方法
    searchParams: Object, // searchParams
  });

  const {
    data: dataSource,
    run,
    loading,
    current,
    pageSize,
    total,
    // changeCurrent,
  } = usePagination(props.fetchData, {
    formatResult: res => res?.data?.data,
    defaultParams: [{
      limit: 5,
      ...(props?.searchParams ? props?.searchParams : {}),
    }],
    pagination: {
      currentKey: 'page',
      pageSizeKey: 'limit',
    },
  });

  onMounted(() => {
    props?.getReload?.(run);
  })

  const pagination = computed(() => ({
    total: total.value,
    current: current.value,
    pageSize: pageSize.value || 5,
    pageSizeOptions: ['5', '10', '20', '50'], // 可选择的分页条数
    showSizeChanger: true, // 允许用户改变每页条数
    showTotal: total => `共 ${total} 条`,
  }));

  const handleTableChange = (
    pag,
    filters,
  ) => {
    run({
      limit: pag.pageSize,
      page: pag?.current,
      ...filters,
    });
  };
</script>

<style module>
  .wrap {
    margin-bottom: 10px;
  }
  .detailWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 19px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 27px;
  }
  .detailLeft {
    display: flex;
    align-items: center;
  }
  .detailRight {
    display: flex;
    align-items: center;
  }
  .detailIcon {
    width: 23px;
    margin-right: 13px;
  }
</style>
