<!-- cdk兑换 -->
<template>
  <div :class="$style.wrap">
    <!-- <h3 :class="$style.title">CDK兑换</h3> -->
    <img :class="$style.titleIcon" :src="zuhaoguanjiaImg" alt=""/>
    <div :class="$style.search">
      <a-input-search
        v-model:value="cdkey"
        placeholder="兑换CDKEY"
        enter-button="CDK提交"
        size="large"
        @search="onSearch"
        :loading="isLoading"
      />
    </div>
    <div :class="$style.describe">
      <div :class="$style.describeTit">温馨提示</div>
      <div :class="$style.describeItem">首次租用的用户兑换前先点击"<a href="/registerUsername" target="_blank" :class="$style.describeA">注册</a>"</div>
      <div :class="$style.describeItem">如果您已经兑换却不知道如何查看账号密码，请点击："<a href="/news/48.html" target="_blank" :class="$style.describeA">查看账号密码方法</a>"</div>
      <div :class="$style.describeItem">为什么要注册？</div>
      <div :class="$style.describeIZ">
        <span :class="$style.describeIZ1">1、可以自助选号</span>
        <span>2、可收Steam登陆验证码,防止顶号</span>
      </div>
      <div :class="$style.describeIZ">
        <span :class="$style.describeIZ3">3、方便下次玩老帐号</span>
        <span>4、免费送时长</span>
      </div>
    </div>
    <a-modal v-model:open="isOpen" title="选择操作" :footer="null" :width="400">
      <div :class="$style.modalWrap">
        <a-button size="large" @click="onUseOld">续期</a-button>
        <a-button size="large" @click="onUseGenNew">兑换</a-button>
      </div>
    </a-modal>
  </div>
</template>
<script setup>
import { ref } from "vue";
import axios from "@/axios";
import { message } from 'ant-design-vue';
import { useRouter } from 'vue-router';

import zuhaoguanjiaImg from "@assets/zuhaoguanjia.png";

const cdkey = ref("");
const isOpen = ref(false);
const router = useRouter();
const isLoading = ref(false);

// 续期
const onUseOld = async() => {
  const res = await axios.post("web/all_cdk_use_old/", { cdkey: cdkey.value}).catch(() => {});
  if (res?.data?.status === 10000) {
    router.push("");
    isOpen.value = false;
  }
}

// 兑换
const onUseGenNew = async() => {
  const res = await axios.post("web/all_cdk_use_gen_new/", { cdkey: cdkey.value}).catch(() => {});
  if (res?.data?.status === 10000) {
    router.push("");
    isOpen.value = false;
  }
}

const onSearch = async(searchValue) => {
  if(!searchValue) return message.warning('请输入CDKEY');
  isLoading.value = true;
  const res = await axios.post("web/all_cdk_use/", { cdkey: searchValue}).catch(() => {});
  isLoading.value = false;
  if (res?.data?.status === 10000) {
    // is_exits：是否存在，如果存在要弹窗让用户选择 续期还是兑换
    if (res?.data?.data?.is_exits) {
      isOpen.value = true;
    } else {
      router.push("");
    }
  }
};
</script>

<style module>
  .wrap {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .titleIcon {
    width: 221px;
    margin-bottom: 71px;
  }
  .search {
    width: 885px;
    margin-bottom: 46px;
  }
  .describe {
    width: 885px;
    padding-left: 35px;
    margin-bottom: 200px;
  }
  .describeTit {
    font-size: 24px;
    color: #A0A0A0;
    line-height: 35px;
    margin-bottom: 28px;
  }
  .describeItem {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 23px;
    display: flex;
    align-items: center;
  }
  .describeItem::before {
    display: inline-block;
    content: '';
    width: 11px;
    height: 11px;
    border-radius: 11px;
    margin-right: 11px;
    background-color: #237AFF;
  }
  .describeIZ {
    font-size: 20px;
    height: 30px;
    line-height: 30px;
    margin-bottom: 15px;
  }
  .describeA {
    color: #237AFF;
    text-decoration: none;
  }
  .describeIZ1 {
    margin-right: 164px;
  }
  .describeIZ3 {
    margin-right: 121px;
  }
  .modalWrap {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
</style>