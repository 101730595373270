<!-- 我的游戏库 -->
<template>
  <div :class="$style.contentLeft">
    <div :class="$style.contentLeftTit">我的游戏库</div>
    <div :class="$style.contentLeftCon">
      <a-spin v-if="leftLoading" />
      <div v-else="!leftLoading" v-for="item of myGameData" :key="item.id" :class="$style.contentLeftItem">
        <div :class="$style.contentLeftItemN">{{ item?.gname || '-' }}</div>
        <div :class="$style.contentLeftItemZ" @click="onRentNumber(item?.id)">
          <div>租号</div>
          <img :class="$style.contentLeftItemZI" :src="rightAIcon" alt="" />
        </div>
      </div>
    </div>
    <RentAccountDialog
      :isOpen="isRentAccountDialog"
      :pkId="rentPkId"
      :rentType="'permanent'"
      @setIsOpen="setIsRentAccountDialog"
      @successCallBack="onRentAccountSuccess"
    />
  </div>
</template>

<script setup>
  import { ref, onMounted } from "vue";
  import axios from "@/axios";
  import { useRouter } from 'vue-router';

  import RentAccountDialog from '@components/SelectAccountModal.vue';
  import rightAIcon from "@assets/rightAIcon.png";

  const router = useRouter();
  const myGameData = ref();
  const leftLoading = ref(false);

  // 获取我的游戏库列表
  onMounted(async () => {
    leftLoading.value = true;
    const res = await axios.post("web/yong_jiu_rent_quan_yi/", { limit: 999 }).catch(() => {});
    leftLoading.value = false;
    if (res?.data?.status === 10000) {
      myGameData.value = res?.data?.data?.items || [];
    }
  });

  const onRentNumber = (yongjiuId) => {
    rentPkId.value = yongjiuId;
    isRentAccountDialog.value = true;
  }

  // 租号业务
  const rentPkId = ref();
  const isRentAccountDialog = ref(false);

  const setIsRentAccountDialog = (flag) => {
    isRentAccountDialog.value = flag;
  }

  const onRentAccountSuccess = () => {
    // 租号成功，弹框提示弹窗
    router.push('/rendResult');
  }
</script>

<style module>
  .contentLeft {
    width: 300px;
    flex-shrink: 0;
    padding-left: 35px;
  }
  .contentLeftTit {
    font-weight: bold;
    font-size: 24px;
    color: #2E2E2E;
  }
  .contentLeftCon {
    text-align: center;
    margin-top: 55px;
    overflow-y: auto;
    max-height: 660px;
  }
  .contentLeftCon::-webkit-scrollbar {
    display: none;
  }
  .contentLeftItem {
    width: 265px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 28px;
    margin-bottom: 28px;
    border-bottom: 1px solid #EBEBEB;
  }
  .contentLeftItemN {
    font-size: 20px;
    text-align: start;
    color: #0F0F0F;
  }
  .contentLeftItemZ {
    flex-shrink: 0;
    font-size: 18px;
    color: #FF870F;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .contentLeftItemZI {
    width: 7px;
    margin-left: 13px;
  }
</style>
