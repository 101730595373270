import store from '@/store/index.js';
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/homeView';
import UserRegister from '../components/RegisterUsername.vue';
import PhoneRegister from '../components/RegisterPhone.vue';
import EmailRegister from '../components/RegisterEmail.vue';
import Login from '../views/Login.vue';
import Recover from '../views/Recover.vue';
import UseCDK from '../views/cdk/useCdk.vue';
import BuyCdk from '../views/cdk/buyCdk.vue';
import RecordCdk from '../views/cdk/recordCdk.vue';
import News from '../views/news/index.vue';

import NotFound from '../views/NotFound.vue';
import UserProfile from '../views/user_centor/UserProfile.vue';
import ProductDetail from '../views/product_centor/ProductDetail.vue';
import Detail from '../views/detail/index.vue';
import MyGame from '../views/myGame/index.vue';
import RendResult from '../views/rendResult/index.vue';
import WalletDetail from '../views/walletDetail/index.vue';
import GameStore from '../views/gameStore/index.vue';
import MyOrder from '../views/myOrder/index.vue';
import Exclusive from '../views/exclusive/index.vue';
import MyCollect from '../views/myCollect/index.vue';
import MyMember from '../views/myMember/index.vue';
import RentalRecords from '../views/rentalRecords/index.vue';

import RentHistory from '../views/dongnanyaView/HistoryRent.vue';
import RentAcive from '../views/dongnanyaView/ActiveRent.vue';
import PermanentRights from '../views/dongnanyaView/YongjiuQuanyiList.vue';
import CdkUse from '../views/dongnanyaView/Cdk.vue';
import TestPay from '../views/TestPay.vue';


const routes = [
    { path: '/registerUsername', meta: { isShowLayout: true }, component: UserRegister },
    { path: '/registerPhone', meta: { isShowLayout: true }, component: PhoneRegister },
    { path: '/registerEmail', meta: { isShowLayout: true }, component: EmailRegister },
    { path: '/register', meta: { isShowLayout: true }, component: PhoneRegister },

    { path: '/login', meta: { isShowLayout: true }, component: Login },
    { path: '/recover', meta: { isShowLayout: true }, component: Recover },
    // { path: '/profile', component: UserProfile, meta: { requiresAuth: true } },
    { path: '/profile', component: UserProfile, },
    { path: '/product/:id', component: ProductDetail }, // 新增
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
    { path: '/detail/:id', component: Detail }, // 游戏详情
    { path: '/myGame', component: MyGame }, // 我的游戏
    { path: '/rendResult', component: RendResult }, // 租号成功结果页
    { path: '/walletDetail', component: WalletDetail }, // 钱包详情
    {
      path: '/gameStore',
      component: GameStore,
      props: route => ({ qrCategory: route.query.category })
    }, // 游戏商城
    { path: '/myOrder', component: MyOrder }, // 我的订单
    { path: '/exclusive', component: Exclusive }, // 我的独享
    { path: '/myCollect', component: MyCollect }, // 我的收藏
    { path: '/myMember', component: MyMember }, // 我的会员
    { path: '/rentalRecords', component: RentalRecords }, // 租用记录
    { path: '/news/:id.html', name: 'News', component: News,},
    // {
    //     path: "/ecommerce/products",
    //     name: "products",
    //     meta: { title: "Products", authRequired: true },
    //     component: () =>
    //         import ("../views/ecommerce/products"),
    // },
    { path: '/cdk', component: UseCDK },
    { path: '/buyCdk', component: BuyCdk },
    { path: '/recordCdk', component: RecordCdk }, // cdk兑换记录
    { path: '/TestPay', component: TestPay },

    // 东南亚相关业务投流测试
    // { path: '/dny/rent_history', component: RentHistory, meta: { requiresAuth: true } },
    // { path: '/dny/rent_active', component: RentAcive, meta: { requiresAuth: true } },
    // { path: '/dny/yongjiuquanyi', component: PermanentRights, meta: { requiresAuth: true } },
    // { path: '/dny/cdk_use', component: CdkUse},
];


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
      // 始终滚动到顶部
      return { top: 0 }
    },
})

// 导航守卫
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        const token = localStorage.getItem('access_token');
        if (!token) {
            next({
                path: '/login',
                query: { redirect: to.fullPath }
            });
        } else {
            next();
        }
    } else {
        next();
    }
    // 头部导航切换
    store.commit('SET_SELECTEDKEYS', [to?.path || '']);
});

export default router