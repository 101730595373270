<!-- 
  <RentAccountDialog
    :isOpen="isRentAccountDialog"
    :pkId="rentPkId"
    :rentType="'permanent'"
    @setIsOpen="setIsRentAccountDialog"
    @successCallBack="onRentAccountSuccess"
  />

  // 租号业务
  import RentAccountDialog from '@components/SelectAccountModal.vue';
  const rentAccountPkId = ref();
  const isRentAccountDialog = ref(false);

  const setIsRentAccountDialog = (flag) => {
    isRentAccountDialog.value = flag;
  }

  const onRentNumber = (yongjiuId) => {
    console.log(yongjiuId)
    rentAccountPkId.value = yongjiuId;
    isRentAccountDialog.value = true;
  }

  const onRentAccountSuccess = () => {
    // 租号成功，弹框提示弹窗
    router.push('/rendResult');
  }

 -->

<template>
  <el-dialog :model-value="isOpen" @close="onCancel" :title="$t('permanentCard.selectAccountTitle')" width="30%">
    <p :class="$style.title">
      &nbsp;{{ $t('permanentCard.violationWarning') }}
    </p>
    <el-select v-model="selectedAccount" :placeholder="$t('permanentCard.selectAccountPlaceholder')" style="width: 80%">
      <el-option
        v-for="account in accountOptions"
        :key="account.id"
        :label="account.name"
        :value="account.id"
        :disabled="!account.is_kongxian"
      />
    </el-select>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="onCancel">{{ $t('permanentCard.cancel') }}</el-button>
        <el-button type="primary" @click="confirmRent">{{ $t('permanentCard.confirm') }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
  import { ref, watch } from 'vue';
  import axios from '@/axios';
  import { useRouter } from 'vue-router'
  import { message } from 'ant-design-vue';
  import { useI18n } from 'vue-i18n';

  const router = useRouter();
  const { t } = useI18n();

  const props = defineProps({
    isOpen: Boolean,
    pkId: Number,
    defaultValue: String, // 默认值
    rentType: String, // 租赁类型：vip、permanent、switch
  });
  const emit = defineEmits(["setIsOpen", "successCallBack"]);
  const selectedAccount = ref(undefined);
  const accountOptions = ref([]);

  // 根据租赁类型获取接口路径
  const getApiPath = (apiType) => {
    const apiMap = {
      vip: {
        list: '/web/rentno/vip_rento/',
        select: '/web/rentno/vip_chioce_account/',
      },
      permanent: {
        list: '/web/rentno/zhhao_account_rental_log/',
        select: '/web/rentno/chioce_account/',
      },
      switch: {
        list: '/web/rentno/qiehuan_list/',
        select: '/web/rentno/qiehuan/',
      },
    };
    return apiMap[props.rentType]?.[apiType];
  };

  // 获取账号列表
  watch([() => props?.pkId, () => props?.isOpen, () => props?.rentType], async () => {
    if (!props?.pkId || !props?.isOpen || !props?.rentType) return;
    const apiPath = getApiPath('list');
    if (!apiPath) {
      console.error(`Invalid rentType: ${props.rentType}`);
      return;
    }
    const res = await axios.post(apiPath, { uid: props?.pkId }).catch(() => {});
    if (res?.data?.status === 10000) {
      accountOptions.value = res?.data?.data || [];
    } else {
      accountOptions.value = [];
    }
  });

  // 设置默认值
  watch([() => props?.defaultValue, () => props?.isOpen], async () => {
    if (!props?.defaultValue || !props?.isOpen) return;
    selectedAccount.value = props?.defaultValue;
  });

  // 点击确认
  const confirmRent = async () => {
    if (!selectedAccount.value) {
      message.warning(t('permanentCard.selectAccountWarning'));
      return;
    }
    const apiPath = getApiPath('select');
    if (!apiPath) {
      console.error(`Invalid rentType: ${props.rentType}`);
      return;
    }
    const response = await axios.post(apiPath, {
      uid: props?.pkId,
      account: selectedAccount.value,
    });
    if (response.data.status === 10000) {
      emit("setIsOpen", false);
      emit("successCallBack");
    }
  };

  const onCancel = () => {
    selectedAccount.value = undefined;
    emit("setIsOpen", false);
  };
</script>

<style module>
  .title {
    color: red;
    font-weight: bold;
    font-size: 14px;
  }
</style>
