<!-- 购买cdk -->
<template>
  <div :class="$style.wrap">
    <div :class="[$style.left, 'bucdk-left']">
      <div :class="$style.leftCon">
        <div :class="$style.leftConL">CDKEY余额</div>
      </div>
      <div :class="$style.leftF">
        <div :class="$style.leftFP">
          <div :class="$style.leftFPP">{{ user?.cdk_amount || '0.00' }}</div>
          <div :class="$style.leftFPD">元</div>
        </div>
        <a-button :class="$style.leftFB" type="primary" shape="round" @click="openCdkPayment">
          充值
        </a-button>
      </div>
      <a-anchor :bounds="100" :targetOffset="100">
        <a-anchor-link :href="activeKey === 'game' ? '#buyCdk' : '#mbuyCdk'" >
          <template #title>
            <div :class="[$style.leftI, pageType === 'buyCdk' && $style.leftIActive]" @click="onChangePT('buyCdk')">购买CDK</div>
          </template>
        </a-anchor-link>
        <a-anchor-link :href="activeKey === 'game' ? '#unUseCdk' : '#munUseCdk'" >
          <template #title>
            <div :class="[$style.leftI, pageType === 'unUseCdk' && $style.leftIActive]" @click="onChangePT('unUseCdk')">未使用CDK</div>
          </template>
        </a-anchor-link>
        <a-anchor-link :href="activeKey === 'game' ? '#usedCdk' : '#musedCdk'" >
          <template #title>
            <div :class="[$style.leftI, pageType === 'usedCdk' && $style.leftIActive]" @click="onChangePT('usedCdk')">已使用CDK</div>
          </template>
        </a-anchor-link>
        <a-anchor-link :href="activeKey === 'game' ? '#refundedCdk' : '#mrefundedCdk'" >
          <template #title>
            <div :class="[$style.leftI, pageType === 'refundedCdk' && $style.leftIActive]" @click="onChangePT('refundedCdk')">已退款CDK</div>
          </template>
        </a-anchor-link>
      </a-anchor>
    </div>
    <div :class="[$style.right, 'buycdk-right']">
      <a-tabs v-model:activeKey="activeKey" :destroyInactiveTabPane="true">
        <a-tab-pane key="game" tab="游戏CDK">
          <BuycdkRight :type="activeKey" />
        </a-tab-pane>
        <a-tab-pane key="member" tab="会员CDK">
          <BuycdkRight :type="activeKey" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
  <PaymentModal
    :isOpen="isOpen"
    :isOrderOpen="isOrderOpen"
    :curSku="curSkuPaymentModal"
    :paymentType="paymentType"
    :orderInfo="orderInfo"
    @setIsOpen="(value) => (isOpen = value)"
    @setIsOrderOpen="(value) => (isOrderOpen = value)"
  />
</template>

<script setup>
  import { ref, computed, onMounted } from "vue";
  import { useStore } from 'vuex';

  import PaymentModal from "@/components/BuyPlanModalv3.vue";
  import BuycdkRight from "./components/BuycdkRight.vue";
  const isOpen = ref(false);
  const isOrderOpen = ref(false);
  const paymentType = ref(0);
  const orderInfo = ref(null);
  const curSkuPaymentModal = ref(null);

  // 模拟 CDK 充值
  const openCdkPayment = () => {
    paymentType.value = 2;
    curSkuPaymentModal.value = null;
    isOpen.value = true;
  };

  const store = useStore();
  const pageType = ref('buyCdk');
  const activeKey = ref('game');


  // 使用 Vuex 的 getter 获取用户信息
  const user = computed(() => store.getters.getUser);

  // 页面挂载时调用 fetchUserInfo action
  onMounted(() => {
    store.dispatch('fetchUserInfo');
  });

  const onChangePT = (pType) => {
    pageType.value = pType;
  }

</script>

<style module>
  .wrap {
    display: flex;
    padding: 23px 230px 0;
  }
  .left {
    flex-shrink: 0;
    width: 264px;
    background-color: #FAFBFA;
    border-radius: 10px;
    padding: 22px 22px;
    color: #1A1A1A;
    margin-right: 46px;
  }
  .leftCon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20px;
    line-height: 20px;
  }
  .leftConL {
    font-size: 14px;
  }
  .leftF {
    height: 33px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .leftFP {
    display: flex;
    align-items: center;
  }
  .leftFPP {
    font-size: 22px;
    color: #FF2727;
    margin-right: 5px;
  }
  .leftFPD {
    font-size: 16px;
    color: #383838;
  }
  .leftFB {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 62px;
    height: 24px;
    font-size: 13px;
    color: #fff;
    background-color: rgba(255, 147, 94, 1);
  }
  .leftFB:hover {
    background-color: rgba(255, 147, 94, .7) !important;
  }
  .leftI {
    width: 100%;
    height: 41px;
    line-height: 41px;
    border-radius: 5px;
    font-size: 18px;
    color: #1A1A1A;
    padding-left: 19px;
    margin-bottom: 8px;
    cursor: pointer;
  }
  .leftIActive {
    background-color: #BDD6FD;
  }
  .right {
    flex-grow: 1;
  }
</style>
<style>
  .buycdk-right .ant-tabs-nav::before {
    border-bottom: none !important;
  }
  .buycdk-right .ant-input-number {
    font-size: 18px !important;
  }
  .buycdk-right .ant-input-number-input {
    height: 40px !important;
  }
  .buycdk-right .ant-select-selector {
    height: 100% !important;
    font-size: 18px !important;
    /* line-height: 40px !important; */
  }
  .buycdk-right .ant-select-selection-search-input {
    height: 40px !important;
  }
  .buycdk-right .ant-select-selection-placeholder{
    line-height: 40px !important;
  }
  .buycdk-right .ant-select-selection-item{
    line-height: 40px !important;
  }
  .bucdk-left .ant-affix {
    top: 110px !important;
  }
  .buycdk-right .ant-input {
    height: 50px !important;
    font-size: 18px !important;
  }
  .buycdk-right .ant-input-search-button {
    height: 50px !important;
    width: 40px !important;
    font-size: 20px !important;
  }
</style>
