// store/realname_verification_modal.js
const realnameVerificationModalModule = {
    namespaced: true,  // 使用命名空间
    state: () => ({
      isRealOpen: false,
    }),
    mutations: {
      OPEN_REAL_MODAL(state) {
        state.isRealOpen = true;
      },
      CLOSE_REAL_MODAL(state) {
        state.isRealOpen = false;
      },
    },
    actions: {
      openRealModal({ commit }) {
        commit('OPEN_REAL_MODAL');
      },
      closeRealModal({ commit }) {
        commit('CLOSE_REAL_MODAL');
      },
    }
  };
  
  export default realnameVerificationModalModule;
  